import React, { useEffect } from 'react'
import Bmg from './Bmg'
import * as credenciaisActions from 'store/modules/backoffice/credenciais/actions'
import useRedux from 'hooks/useRedux'
import Itau from './Itau'
import CredenciaisAgente from './CredenciaisAgente'
import Crefaz from './Crefaz'

const ConsigScreen = () => {
  const { dispatch } = useRedux()

  useEffect(() => {
    dispatch(credenciaisActions.getUsuariosInstituicaoBmgSaga())
    dispatch(credenciaisActions.getUsuariosInstituicaoItauSaga())
    dispatch(credenciaisActions.getUsuarioPrincipalBMGSaga())
    dispatch(credenciaisActions.getUsuarioPrincipalItauSaga())
    dispatch(credenciaisActions.getCredenciaisAgenteSaga())
    dispatch(credenciaisActions.getCredencialCrefazSaga())
  }, [dispatch])
  return (
    <div className="d-flex flex-column row-gap-10">
      <Bmg />
      <Itau />
      <Crefaz />
      <CredenciaisAgente />
    </div>
  )
}

export default ConsigScreen

import React, { useMemo, useState } from 'react'
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap'
import { PopoverInfoWrapper } from './style'
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import { generateGuid } from 'util/generateGuid'

interface Props {
  title: string
  message: string
}

const PopoverInfo = ({ title, message }: Props) => {
  const [popoverOpen, setPopoverOpen] = useState(false)

  const uniqueId = useMemo(() => generateGuid(), [])

  const togglePopover = () => {
    setPopoverOpen(!popoverOpen)
  }

  return (
    <PopoverInfoWrapper>
      <AiOutlineQuestionCircle
        id={`PopoverControlled-${uniqueId}`}
        className="icon-popover"
        onMouseOver={() => {
          setPopoverOpen(true)
        }}
        onMouseOut={() => {
          setPopoverOpen(false)
        }}
      />

      <Popover
        placement="bottom"
        isOpen={popoverOpen}
        target={`PopoverControlled-${uniqueId}`}
        toggle={togglePopover}
      >
        <PopoverHeader>{title}</PopoverHeader>
        <PopoverBody>{message}</PopoverBody>
      </Popover>
    </PopoverInfoWrapper>
  )
}

export default PopoverInfo

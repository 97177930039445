import { Paginacao } from 'models/types'
import {
  AcompanhamentoProducaoFieldsTabela,
  Equipe,
  PRODUCAO,
  TYPES,
  Usuario
} from './types'
import store from 'store'

type Fields = keyof PRODUCAO['filtros']
export const setFiltros = (value: string, field: Fields) => ({
  type: TYPES.SET_FILTROS,
  value,
  field
})

export const cleanFiltros = () => ({
  type: TYPES.CLEAN_FILTROS
})

export const findSaga = () => ({
  type: TYPES.FIND_SAGA
})

export const setPaginacao = (value: Paginacao) => ({
  type: TYPES.SET_PAGINATION,
  value
})

export const getEquipesSaga = (flagUsuario?: boolean) => ({
  type: TYPES.GET_EQUIPES_SAGA,
  flagUsuario
})

export const getUsuariosSaga = (lojaId?: string) => ({
  type: TYPES.GET_USUARIOS_SAGA,
  lojaId
})

export const setUsuariosOptions = (value: Usuario[]) => ({
  type: TYPES.SET_USUARIOS_OPTIONS,
  value
})

export const setEquipesOptions = (value: Equipe[]) => ({
  type: TYPES.SET_EQUIPES_OPTIONS,
  value
})

export const setTabelaAcompanhamentoProducao = (
  value: AcompanhamentoProducaoFieldsTabela[]
) => ({
  type: TYPES.SET_TABELA_ACOMPANHAMENTO_PRODUCAO,
  value
})

export const cleanTabelaAcompanhamentoProducao = () => ({
  type: TYPES.CLEAN_TABELA_ACOMPANHAMENTO_PRODUCAO
})

type FieldsModalPendenciaCrefaz = PRODUCAO['modalPendenciasAnexoCrefaz']
export const setModalPendenciasAnexoCrefazFields = <
  T extends keyof FieldsModalPendenciaCrefaz
>(
  value: FieldsModalPendenciaCrefaz[T],
  field: T
) => ({
  type: TYPES.SET_MODAL_PENDENCIAS_ANEXO_CREFAZ_FIELDS,
  value,
  field
})

export const setModalPendenciasAnexoCrefazFieldsDispatch = <
  T extends keyof FieldsModalPendenciaCrefaz
>(
  value: FieldsModalPendenciaCrefaz[T],
  field: T
) =>
  store.dispatch({
    type: TYPES.SET_MODAL_PENDENCIAS_ANEXO_CREFAZ_FIELDS,
    value,
    field
  })

export const getAnexosSaga = () => ({
  type: TYPES.GET_ANEXOS_SAGA
})

export const getTiposArquivoCrefazSaga = () => ({
  type: TYPES.GET_TIPOS_ARQUIVO_CREFAZ_SAGA
})

export const salvarAnexoSaga = () => ({
  type: TYPES.SALVAR_ANEXO_SAGA
})

export const cleanModalPendenciasAnexoCrefaz = () => ({
  type: TYPES.CLEAN_MODAL_PENDENCIAS_ANEXO_CREFAZ
})

export const visualizarAnexoSagaDispatch = (nomeArquivo: string) =>
  store.dispatch({
    type: TYPES.VISUALIZAR_ANEXO_SAGA_DISPATCH,
    nomeArquivo
  })

export const downloadAnexoSagaDispatch = (nomeArquivo: string) =>
  store.dispatch({
    type: TYPES.DOWNLOAD_ANEXO_SAGA_DISPATCH,
    nomeArquivo
  })

export const deletarAnexoSagaDispatch = (nomeArquivo: string) =>
  store.dispatch({
    type: TYPES.DELETAR_ANEXO_SAGA_DISPATCH,
    nomeArquivo
  })

import React, { memo, useCallback, useEffect, useState } from 'react'
import SimpleCard from 'components/SimpleCard'
import useRedux from 'hooks/useRedux'
import { useSelector } from 'react-redux'
import { Col, Label, Row, TabPane } from 'reactstrap'
import { ApplicationState } from 'store'
import { ATENDIMENTO } from 'store/modules/atendimento/types'
import CustomSelect from 'components/Select'
import { GrayCardUpload } from 'views/atendimento/style'
import { UploadArquivo } from 'components/UploadArquivosComponent'
import { MdOutlineFileUpload } from 'react-icons/md'
import CustomTable from 'components/CustomTable'
import { COLUMNS_UPLOAD_ANEXOS } from 'views/atendimento/constants/columns'
import * as atendimentoActions from 'store/modules/atendimento/actions'
import { toast } from 'react-toastify'

/**
 * Upload
 * TabId = 5
 * **/

interface Props {
  index: number
}

const Upload = ({ index }: Props) => {
  const { atendimentosAbertos } = useSelector<ApplicationState, ATENDIMENTO>(
    (state) => state.atendimento
  )

  const [isTipoArquivoInvalid, setIsTipoArquivoInvalid] = useState(false)
  const [isUploadedFileInvalid, setIsUploadedFileInvalid] = useState(false)

  const { dispatch } = useRedux()

  type InfosUploadField =
    keyof ATENDIMENTO['atendimentosAbertos'][number]['tabUpload']['register']

  const onChange = useCallback(
    (
      value: InfosUploadField extends keyof ATENDIMENTO['atendimentosAbertos'][number]['tabUpload']['register']
        ? ATENDIMENTO['atendimentosAbertos'][number]['tabUpload']['register'][InfosUploadField]
        : never,
      field: InfosUploadField
    ) => {
      const register = {
        ...atendimentosAbertos[index].tabUpload.register,
        [field]: value
      }
      dispatch(
        atendimentoActions.setInfosUploadField(register, 'register', index)
      )
    },
    [dispatch, atendimentosAbertos, index]
  )

  useEffect(() => {
    dispatch(atendimentoActions.getTiposArquivoCrefazSaga(index))
    dispatch(atendimentoActions.getAnexosSaga(index))
  }, [dispatch, index])

  const setPage = useCallback(
    (value: number) => {
      dispatch(
        atendimentoActions.setInfosUploadField(
          {
            ...atendimentosAbertos[index].tabUpload.paginacaoAnexos,
            page: value
          },
          'paginacaoAnexos',
          index
        )
      )
      dispatch(atendimentoActions.getAnexosSaga(index))
    },
    [dispatch, atendimentosAbertos, index]
  )

  const setPageSize = useCallback(
    (value: number) => {
      dispatch(
        atendimentoActions.setInfosUploadField(
          {
            ...atendimentosAbertos[index].tabUpload.paginacaoAnexos,
            page: 0,
            pageSize: value
          },
          'paginacaoAnexos',
          index
        )
      )
      dispatch(atendimentoActions.getAnexosSaga(index))
    },
    [dispatch, atendimentosAbertos, index]
  )

  const onSave = () => {
    const tipoArquivo =
      atendimentosAbertos[index].tabUpload.register.tipoArquivo
    const uploadedFile =
      atendimentosAbertos[index].tabUpload.register.uploadedFile

    setIsTipoArquivoInvalid(!tipoArquivo)
    setIsUploadedFileInvalid(!uploadedFile)

    if (tipoArquivo && uploadedFile) {
      dispatch(atendimentoActions.salvarAnexoSaga(index))
    } else {
      toast.error('Preencha todos os campos obrigatórios antes de salvar')
    }
  }

  return (
    <TabPane tabId={5}>
      <div className="container-fluid">
        <SimpleCard className="d-flex flex-column row-gap-20">
          <GrayCardUpload>
            <h4 className="d-flex align-items-center column-gap-10">
              Envio de documentos
            </h4>
            <Col md={4}>
              <Label className="label-12">Tipo de documento*</Label>
              <CustomSelect
                options={atendimentosAbertos[index].tabUpload.tiposArquivo}
                value={
                  atendimentosAbertos[index].tabUpload.register.tipoArquivo
                }
                onChange={(e) => {
                  onChange(e, 'tipoArquivo')
                }}
                accessorLabel="descricao"
                accessorValue="id"
                invalid={isTipoArquivoInvalid}
              />
            </Col>
            <Row className="mt-4">
              <UploadArquivo
                onChange={(e) => {
                  onChange(e, 'uploadedFile')
                }}
                value={
                  atendimentosAbertos[index].tabUpload.register.uploadedFile
                }
                invalid={isUploadedFileInvalid}
                removeSection
                acceptedFiles={{
                  'image/png': ['.png'],
                  'image/jpeg': ['.jpeg', '.jpg'],
                  'application/pdf': ['.pdf']
                }}
                message="Somente arquivos PNG, JPEG e PDF"
              />
            </Row>
            <Row className="mt-4">
              <div onClick={onSave} className="add-btn btn">
                <MdOutlineFileUpload className="icon" />
                Clique para enviar
              </div>
            </Row>
          </GrayCardUpload>
          <CustomTable
            columns={COLUMNS_UPLOAD_ANEXOS}
            data={atendimentosAbertos[index].tabUpload.anexos}
            paginationMode="server"
            rowCount={
              atendimentosAbertos[index].tabUpload.paginacaoAnexos
                .totalRegistros
            }
            page={atendimentosAbertos[index].tabUpload.paginacaoAnexos.page}
            pageSize={
              atendimentosAbertos[index].tabUpload.paginacaoAnexos.pageSize
            }
            setPage={(e) => {
              setPage(e)
            }}
            setPageSize={(e) => {
              setPageSize(e)
            }}
          />
        </SimpleCard>
      </div>
    </TabPane>
  )
}

export default memo(Upload)

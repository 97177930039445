import React, { useEffect } from 'react'
import SimpleCard from 'components/SimpleCard'
import Main from 'layouts/main'
import {
  AiOutlineDownload,
  AiOutlineFilter,
  AiOutlinePieChart
} from 'react-icons/ai'
import { CustomButton, SimpleTitleCard } from 'styles/global'
import { Col, Row } from 'reactstrap'
import InputCustom from 'components/Inputs'
import CustomSelect from 'components/Select'
import BlankSpace from './components/BlankSpace'
import { BtnExportacao } from './style'
import classNames from 'classnames'
import CustomTable from 'components/CustomTable'
import { COLUMNS_HISTORICO } from './constants/columns'
import { useSelector } from 'react-redux'
import { PRODUCAO } from 'store/modules/producao/types'
import { ApplicationState } from 'store'
import useRedux from 'hooks/useRedux'
import * as producaoActions from 'store/modules/producao/actions'
import { CSVLink } from 'react-csv'
import { maskCPF } from 'util/masks'
import PrivateComponent from 'components/PrivateComponent'
import PendenciaAnexoCrefaz from './components/Modals/PendenciaAnexoCrefaz'

const Producao = () => {
  const {
    filtros,
    tabelaAcompanhamentoProducao,
    usuarios,
    equipes,
    pagination,
    modalPendenciasAnexoCrefaz
  } = useSelector<ApplicationState, PRODUCAO>((state) => state.producao)
  const { dispatch } = useRedux()

  type Fields = keyof PRODUCAO['filtros']
  function onChange(value: string, field: Fields) {
    if (field === 'equipeId') {
      dispatch(producaoActions.setFiltros('', 'digitadorId'))
    }
    dispatch(producaoActions.setFiltros(value, field))
  }

  function aplicarFiltros() {
    dispatch(producaoActions.setPaginacao({ ...pagination, page: 0 }))
    dispatch(producaoActions.findSaga())
  }

  function limparFiltros() {
    dispatch(producaoActions.cleanFiltros())
  }

  function setPage(value: number) {
    dispatch(producaoActions.setPaginacao({ ...pagination, page: value }))
    dispatch(producaoActions.findSaga())
  }

  function setPageSize(value: number) {
    dispatch(
      producaoActions.setPaginacao({ ...pagination, page: 0, pageSize: value })
    )
    dispatch(producaoActions.findSaga())
  }

  useEffect(() => {
    dispatch(producaoActions.getEquipesSaga(true))
  }, [dispatch])

  useEffect(() => {
    dispatch(producaoActions.getUsuariosSaga(filtros.equipeId))
  }, [dispatch, filtros.equipeId])

  const toggleModalPendenciasAnexoCrefaz = () => {
    dispatch(
      producaoActions.setModalPendenciasAnexoCrefazFields(
        !modalPendenciasAnexoCrefaz.isOpen,
        'isOpen'
      )
    )
  }

  const headers = [
    { label: 'Usuário', key: 'nomeDigitador' },
    { label: 'Equipe', key: 'equipe' },
    { label: 'Data e hora', key: 'dataCriacao' },
    { label: 'Nome do cliente', key: 'nomeCliente' },
    { label: 'CPF', key: 'cpf' },
    { label: 'Adesão', key: 'numeroProposta' },
    { label: 'Banco', key: 'banco' },
    { label: 'Valor total', key: 'valorTotal' },
    { label: 'Valor da parcela', key: 'valorParcela' },
    { label: 'Quantidade de parcelas', key: 'quantidadeParcelas' },
    { label: 'Status', key: 'status' },
    { label: 'Produto', key: 'produto' },
    { label: 'Possui Seguro', key: 'possuiSeguro' },
    { label: 'Motivo Pendência', key: 'motivoPendencia' }
  ]

  const csvReport = {
    data: tabelaAcompanhamentoProducao.map((item) => ({
      ...item,
      possuiSeguro: item.possuiSeguro ? 'Sim' : 'Não'
    })),
    headers: headers,
    filename: 'PlanilhaAcompanhamentoProducao.csv'
  }

  return (
    <Main>
      <SimpleCard>
        <div className="container-fluid d-flex flex-column row-gap-10">
          <SimpleTitleCard>
            <AiOutlineFilter className="icon" />
            Filtro
          </SimpleTitleCard>
          <Row>
            <Col md={3}>
              <label className="label-12">Data inicial</label>
              <InputCustom
                type="date"
                placeholder="Digite aqui"
                onChange={(e) => {
                  onChange(e.target.value, 'dataInicio')
                }}
                value={filtros.dataInicio}
              />
            </Col>
            <Col md={3}>
              <label className="label-12">Data final</label>
              <InputCustom
                type="date"
                placeholder="Digite aqui"
                onChange={(e) => {
                  onChange(e.target.value, 'dataFim')
                }}
                value={filtros.dataFim}
              />
            </Col>
            <Col md={3}>
              <label className="label-12">Equipe</label>
              <CustomSelect
                options={equipes}
                placeholder="Selecione"
                onChange={(e) => {
                  onChange(e, 'equipeId')
                }}
                value={filtros.equipeId}
                accessorLabel="nome"
                accessorValue="id"
              />
            </Col>
            <Col md={3}>
              <label className="label-12">Usuário</label>
              <CustomSelect
                options={usuarios}
                placeholder="Selecione"
                onChange={(e) => {
                  onChange(e, 'digitadorId')
                }}
                value={filtros.digitadorId}
                accessorLabel="nome"
                accessorValue="id"
              />
            </Col>
            <Col md={3}>
              <label className="label-12">Adesão</label>
              <InputCustom
                type="text"
                placeholder="Digite aqui"
                onChange={(e) => {
                  onChange(e.target.value, 'adesao')
                }}
                value={filtros.adesao}
              />
            </Col>
            <Col md={3}>
              <label className="label-12">CPF</label>
              <InputCustom
                type="text"
                placeholder="Digite aqui"
                onChange={(e) => {
                  onChange(maskCPF(e.target.value), 'cpf')
                }}
                value={filtros.cpf}
              />
            </Col>
          </Row>
          <Row>
            <Col className="d-flex gap-3">
              <CustomButton
                letterSpacing={2}
                background="#662d91"
                color="#fff"
                justifyContent="center"
                onClick={() => {
                  limparFiltros()
                  aplicarFiltros()
                }}
              >
                Limpar filtros
              </CustomButton>
              <CustomButton
                letterSpacing={2}
                color="#fff"
                justifyContent="center"
                onClick={() => {
                  aplicarFiltros()
                }}
              >
                Aplicar filtros
              </CustomButton>
            </Col>
          </Row>
        </div>
      </SimpleCard>
      <SimpleCard>
        <div className="container-fluid">
          <SimpleTitleCard>
            <AiOutlinePieChart className="icon" />
            Produção
          </SimpleTitleCard>
          <PrivateComponent typeActions="exportar" screen="producao">
            <Row>
              <Col>
                <BtnExportacao
                  className={classNames(
                    tabelaAcompanhamentoProducao.length > 0 && 'active'
                  )}
                >
                  <CSVLink {...csvReport}>
                    <AiOutlineDownload />
                    Exportar Tabela
                  </CSVLink>
                </BtnExportacao>
              </Col>
            </Row>
          </PrivateComponent>
          {tabelaAcompanhamentoProducao.length > 0 ? (
            <CustomTable
              data={tabelaAcompanhamentoProducao}
              columns={COLUMNS_HISTORICO}
              rowCount={pagination.totalRegistros}
              page={pagination.page}
              pageSize={pagination.pageSize}
              setPage={(e) => setPage(e)}
              setPageSize={(e) => setPageSize(e)}
              autoHeight
            />
          ) : (
            <BlankSpace />
          )}
        </div>
      </SimpleCard>
      <PendenciaAnexoCrefaz
        isOpen={modalPendenciasAnexoCrefaz.isOpen}
        onToggle={() => {
          toggleModalPendenciasAnexoCrefaz()
          dispatch(producaoActions.cleanModalPendenciasAnexoCrefaz())
        }}
      />
    </Main>
  )
}

export default Producao

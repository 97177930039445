import { DropzoneState } from 'react-dropzone'
import styled, { css } from 'styled-components'

interface DropContainerProps extends DropzoneState {
  invalid: boolean
}

export const UploadArquivoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;

  & .preview {
    align-items: center;
    display: flex;
    gap: 10px;

    & span {
      font-size: 12px;
      font-weight: 700;
    }

    & .icon {
      width: 48px;
    }
  }

  & .remove-section {
    align-items: center;
    display: flex;
    gap: 0.5rem;

    & span {
      font-size: 16px;
    }

    & .icon {
      color: red;
    }
  }
`

const dragActive = css`
  border-color: #000;
  height: 158px;
`

export const DropContainer = styled.div<DropContainerProps>`
  align-items: center;
  border: 2px dashed #ced4da;
  border-radius: 4px;
  color: #cecece;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-weight: 700;
  justify-content: center;
  padding: 15px 0;
  transition: height 0.2s ease;
  width: 100%;

  border-color: ${({ invalid }) => invalid && 'red'};

  ${({ isDragActive }) => isDragActive && dragActive};

  & label {
    font-size: 18px;
  }

  & .icon {
    font-size: 100px;
  }
`

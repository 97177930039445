import React, { useEffect, useState, useMemo } from 'react'
import CustomSelect from 'components/Select'
import { maskMoney, removeMaskMoney } from 'util/masks'
import {
  ContentCardContratoRefinItau,
  CheckboxContrato,
  SpanWrap
} from '../style'
import { HiOutlinePencil } from 'react-icons/hi'
import { useDispatch, useSelector } from 'react-redux'
import * as atendimentoActions from 'store/modules/atendimento/actions'
import { ApplicationState } from 'store'
import { ATENDIMENTO } from 'store/modules/atendimento/types'
import { ProdutoSimulacao } from 'models/types'
import { CustomButton } from 'styles/global'
import { ErrorCardContratoRefinItau } from './ErrorCardContratoRefinItau'
import LoadingBasic from './LoadingBasic'
import { Col, Row } from 'reactstrap'

interface CardContratoPortabilidadeProps {
  id: string
  codigoBanco: string
  index: number
  parcelasSelected: number
  propostaId: string
  simulacao: ProdutoSimulacao
  numeroProposta: string
  valorParcela: number | null
  quantidadeTotalParcela: number | null
  quantidadeParcelaEmAberto: number | null
  taxaOrigem: number | null
  valorSaldoDevedor: number | null
  erro: string | null
  clienteElegivel: boolean
  listProdutosPortabilidade: {
    codigo: number
    descricao: string
    banco: number
    taxa: number
    produtosAssociados: {
      codigo: number
      descricao: string
      banco: number
      valorTotalFinanciado: number
      valorParcela: number
      listParcelas: number[]
    }[]
  }[]
  indexContrato: number
}

export function CardContratoPortabilidade({
  id,
  codigoBanco,
  numeroProposta,
  valorParcela,
  quantidadeTotalParcela,
  quantidadeParcelaEmAberto,
  taxaOrigem,
  valorSaldoDevedor,
  erro,
  clienteElegivel,
  listProdutosPortabilidade,
  parcelasSelected,
  propostaId,
  index,
  simulacao,
  indexContrato
}: CardContratoPortabilidadeProps) {
  const dispatch = useDispatch()
  const { atendimentosAbertos } = useSelector<ApplicationState, ATENDIMENTO>(
    (state) => state.atendimento
  )
  const isChecked = useMemo(() => {
    return atendimentosAbertos[index].tabProduto.produtosSelecionados?.some(
      (produto) =>
        produto.contratosPortabilidade?.some(
          (item) => item.propostaId === propostaId
        ) && simulacao.matricula === produto.matricula
    )
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    ...atendimentosAbertos[index].tabProduto.produtosSelecionados.flatMap(
      (produtoSelecionado) => [
        ...(produtoSelecionado.contratosPortabilidade?.flatMap(
          (contratoPortabilidade) => [
            ...(contratoPortabilidade.listProdutosPortabilidade ?? [])
          ]
        ) || [])
      ]
    ),
    index,
    propostaId,
    simulacao.matricula
  ])
  /* eslint-enable react-hooks/exhaustive-deps */
  const [propostas] = useState(atendimentosAbertos)
  const [loadingRecalculo] = useState(false)
  const [portabilidadeSelecionada, setPortabilidadeSelecionada] = useState<
    number | null
  >(listProdutosPortabilidade?.[0]?.codigo)
  const [refinPortabilidadeSelecionado, setRefinPortabilidadeSelecionado] =
    useState<number | null>(
      listProdutosPortabilidade?.[0].produtosAssociados?.[0].codigo
    )

  const [indexPortabilidadeSelecionada, setIndexPortabilidadeSelecionada] =
    useState<number | null>(null)
  const [
    indexRefinPortabilidadeSelecionado,
    setIndexRefinPortabilidadeSelecionado
  ] = useState<number | null>(null)

  const [editField, setEditField] = useState<string | null>(null)

  const [editValues, setEditValues] = useState({
    numeroProposta: numeroProposta,
    valorParcela: maskMoney(valorParcela?.toFixed(2)),
    quantidadeParcelaEmAberto: quantidadeParcelaEmAberto,
    valorSaldoDevedor: maskMoney(valorSaldoDevedor?.toFixed(2)),
    valorTotalFinanciado: maskMoney(
      listProdutosPortabilidade?.[
        indexPortabilidadeSelecionada ?? 0
      ].produtosAssociados?.[
        indexRefinPortabilidadeSelecionado ?? 0
      ].valorTotalFinanciado?.toFixed(2)
    )
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleInputChange = (field: string, value: any) => {
    setEditValues((prevValues) => ({
      ...prevValues,
      [field]: value
    }))
  }

  const handleKeyDown = (e: React.KeyboardEvent, field: string) => {
    if (e.key === 'Enter') {
      toggleEdit(field)
    }
  }

  const toggleEdit = (field: string) => {
    if (editField === field) {
      setEditField(null)
    } else {
      setEditField(field)
    }
  }

  const onCheck = () => {
    const produtosSelecionados = JSON.parse(
      JSON.stringify(atendimentosAbertos[index].tabProduto.produtosSelecionados)
    ) as ProdutoSimulacao[]

    const produtoDisponivelAtual = atendimentosAbertos[
      index
    ].tabProduto.produtosDisponiveis.find(
      (produto) =>
        produto.servico === 3 && produto.matricula === simulacao.matricula
    )

    const produtoSelecionadoIndex = produtosSelecionados.findIndex(
      (produto) =>
        produto.servico === 3 && produto.matricula === simulacao.matricula
    )

    if (produtoDisponivelAtual && produtoSelecionadoIndex !== -1) {
      if (isChecked) {
        produtosSelecionados[produtoSelecionadoIndex].contratosPortabilidade =
          produtosSelecionados[
            produtoSelecionadoIndex
          ].contratosPortabilidade?.filter(
            (item) => item.propostaId !== propostaId
          ) ?? []
      } else {
        const contratoParaAdicionar =
          produtoDisponivelAtual.contratosPortabilidade?.find(
            (item) => item.propostaId === propostaId
          )
        if (contratoParaAdicionar) {
          produtosSelecionados[
            produtoSelecionadoIndex
          ].contratosPortabilidade?.push(contratoParaAdicionar)
        }
      }
    } else {
      if (produtoDisponivelAtual) {
        const contratoParaAdicionar =
          produtoDisponivelAtual.contratosPortabilidade?.find(
            (item) => item.propostaId === propostaId
          )
        if (contratoParaAdicionar) {
          produtosSelecionados.push({
            ...produtoDisponivelAtual,
            contratosPortabilidade: [contratoParaAdicionar]
          })
        }
      }
    }

    const portabilidade = produtosSelecionados.find(
      (produtoSelecionado) =>
        produtoSelecionado.servico === 3 &&
        produtoSelecionado.matricula === simulacao.matricula
    )

    const contratosElegiveis = portabilidade?.contratosPortabilidade?.filter(
      (contratoPortabilidade) => contratoPortabilidade.clienteElegivel
    )

    if (contratosElegiveis?.length === 0 && produtoSelecionadoIndex !== -1) {
      produtosSelecionados?.splice(produtoSelecionadoIndex, 1)
    }

    if (
      JSON.stringify(produtosSelecionados) !==
      JSON.stringify(atendimentosAbertos[index].tabProduto.produtosSelecionados)
    ) {
      dispatch(
        atendimentoActions.setInfosProduto(
          produtosSelecionados,
          'produtosSelecionados',
          index
        )
      )
    }
  }

  const handleRecalculoClick = () => {
    const cpf = atendimentosAbertos[index].cpf
    const numeroMatricula = simulacao.matricula
    const quantidadeparcelas = editValues.quantidadeParcelaEmAberto ?? 0
    const valorTotalFinanciado =
      listProdutosPortabilidade[indexPortabilidadeSelecionada ?? 0]
        .produtosAssociados[indexRefinPortabilidadeSelecionado ?? 0]
        .valorTotalFinanciado
    const valorParcela = Number(
      removeMaskMoney(editValues.valorParcela || null)
    )
    const servico = simulacao.servico
    const codigoProduto =
      listProdutosPortabilidade[indexPortabilidadeSelecionada ?? 0].codigo
    const banco =
      listProdutosPortabilidade[indexPortabilidadeSelecionada ?? 0].banco
    const numeroProposta = editValues.numeroProposta
    const saldoDevedor = Number(
      removeMaskMoney(editValues.valorSaldoDevedor || null)
    )

    dispatch(
      atendimentoActions.recalculoPropostaSaga(
        cpf,
        numeroMatricula,
        quantidadeparcelas,
        valorTotalFinanciado,
        valorParcela,
        servico,
        codigoProduto,
        banco,
        numeroProposta,
        taxaOrigem,
        quantidadeTotalParcela ?? 0,
        saldoDevedor,
        index,
        indexPortabilidadeSelecionada,
        indexRefinPortabilidadeSelecionado,
        codigoBanco
      )
    )
  }

  useEffect(() => {
    if (propostas[index].tabProduto.produtosSelecionados) {
      const isChecked = propostas[index].tabProduto.produtosSelecionados.some(
        (produto) => produto.servico === 3
      )
      if (isChecked) {
        setEditValues({
          numeroProposta: numeroProposta,
          valorParcela: maskMoney(valorParcela?.toFixed(2)),
          quantidadeParcelaEmAberto: quantidadeParcelaEmAberto,
          valorSaldoDevedor: maskMoney(valorSaldoDevedor?.toFixed(2)),
          valorTotalFinanciado: maskMoney(
            listProdutosPortabilidade[
              indexPortabilidadeSelecionada ?? 0
            ].produtosAssociados[
              indexRefinPortabilidadeSelecionado ?? 0
            ].valorTotalFinanciado?.toFixed(2)
          )
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propostas])

  useEffect(() => {
    setEditValues({
      numeroProposta: numeroProposta,
      valorParcela: maskMoney(valorParcela?.toFixed(2)),
      quantidadeParcelaEmAberto: quantidadeParcelaEmAberto,
      valorSaldoDevedor: maskMoney(valorSaldoDevedor?.toFixed(2)),
      valorTotalFinanciado: maskMoney(
        listProdutosPortabilidade?.[
          indexPortabilidadeSelecionada ?? 0
        ].produtosAssociados?.[
          indexRefinPortabilidadeSelecionado ?? 0
        ].valorTotalFinanciado?.toFixed(2)
      )
    })
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    propostas[index].tabProduto.produtosSelecionados,
    propostas[index].tabProduto.produtosDisponiveis,
    indexPortabilidadeSelecionada,
    indexRefinPortabilidadeSelecionado
  ])
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    const indexPortabilidadeAux = listProdutosPortabilidade?.findIndex(
      (produtoPortabilidade) =>
        produtoPortabilidade.codigo === portabilidadeSelecionada
    )
    const indexRefinPortabilidadeAux = listProdutosPortabilidade?.[
      indexPortabilidadeAux
    ].produtosAssociados?.findIndex(
      (produtoAssociado) =>
        produtoAssociado.codigo === refinPortabilidadeSelecionado
    )
    if (indexPortabilidadeAux !== -1) {
      setIndexPortabilidadeSelecionada(indexPortabilidadeAux)
    }

    if (indexRefinPortabilidadeAux !== -1) {
      setIndexRefinPortabilidadeSelecionado(indexRefinPortabilidadeAux)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portabilidadeSelecionada, refinPortabilidadeSelecionado])

  return (
    <ContentCardContratoRefinItau>
      <div className="d-flex flex-column w-100">
        <Row>
          <Col
            md={1}
            className="d-flex flex-column align-items-start column-gap-20"
          >
            <label className="fw-bold">Banco</label>
            <SpanWrap>{codigoBanco}</SpanWrap>
          </Col>
          <Col
            md={2}
            className="d-flex flex-column align-items-start column-gap-20"
          >
            <label className="fw-bold">Contrato</label>
            <div className="d-flex align-items-center w-100">
              <SpanWrap>{editValues.numeroProposta}</SpanWrap>
            </div>
          </Col>
          <Col
            md={2}
            className="d-flex flex-column align-items-start column-gap-20"
          >
            <label className="fw-bold">Valor Parcela:</label>

            {editField === 'valorParcela' ? (
              <input
                type="text"
                className="form-control"
                value={editValues.valorParcela || ''}
                onChange={(e) =>
                  handleInputChange('valorParcela', maskMoney(e.target.value))
                }
                onBlur={() => toggleEdit('valorParcela')}
                onKeyDown={(e) => handleKeyDown(e, 'valorParcela')}
              />
            ) : (
              <div className="d-flex align-items-center w-100">
                <SpanWrap>{`R$ ${maskMoney(
                  editValues.valorParcela
                )}`}</SpanWrap>
                <HiOutlinePencil
                  className="ms-2"
                  style={{ cursor: 'pointer' }}
                  onClick={() => toggleEdit('valorParcela')}
                />
              </div>
            )}
          </Col>
          <Col
            md={2}
            className="d-flex flex-column align-items-start column-gap-20"
          >
            <label className="fw-bold">Em aberto</label>

            {editField === 'quantidadeParcelaEmAberto' ? (
              <input
                type="text"
                className="form-control"
                value={editValues.quantidadeParcelaEmAberto || ''}
                onChange={(e) =>
                  handleInputChange(
                    'quantidadeParcelaEmAberto',
                    parseInt(e.target.value) || 0
                  )
                }
                onBlur={() => toggleEdit('quantidadeParcelaEmAberto')}
                onKeyDown={(e) => handleKeyDown(e, 'quantidadeParcelaEmAberto')}
              />
            ) : (
              <div className="d-flex align-items-center w-100">
                <SpanWrap>
                  {editValues.quantidadeParcelaEmAberto} de{' '}
                  {quantidadeTotalParcela}
                </SpanWrap>
                <HiOutlinePencil
                  style={{ cursor: 'pointer' }}
                  className="ms-2"
                  onClick={() => toggleEdit('quantidadeParcelaEmAberto')}
                />
              </div>
            )}
          </Col>
          <Col
            md={2}
            className="d-flex flex-column align-items-start column-gap-20"
          >
            <label className="fw-bold">Taxa origem</label>
            <div>
              <SpanWrap>
                {taxaOrigem !== null ? taxaOrigem.toFixed(2) : null}% a.m
              </SpanWrap>
            </div>
          </Col>
          {clienteElegivel && (
            <Col
              md={3}
              className="d-flex flex-column align-items-start column-gap-20"
            >
              <label className="fw-bold">Saldo devedor</label>

              {editField === 'valorSaldoDevedor' ? (
                <input
                  type="text"
                  className="form-control"
                  value={maskMoney(editValues.valorSaldoDevedor) || ''}
                  onChange={(e) =>
                    handleInputChange(
                      'valorSaldoDevedor',
                      maskMoney(e.target.value)
                    )
                  }
                  onBlur={() => toggleEdit('valorSaldoDevedor')}
                  onKeyDown={(e) => handleKeyDown(e, 'valorSaldoDevedor')}
                />
              ) : (
                <div className="d-flex align-items-center w-100">
                  <SpanWrap>{`R$ ${maskMoney(
                    editValues.valorSaldoDevedor
                  )}`}</SpanWrap>
                  <HiOutlinePencil
                    className="ms-2"
                    style={{ cursor: 'pointer' }}
                    onClick={() => toggleEdit('valorSaldoDevedor')}
                  />
                </div>
              )}
            </Col>
          )}
        </Row>
        <div className="d-flex flex-wrap w-100 mb-3 p-2">
          {clienteElegivel && (
            <div className="d-flex flex-column align-items-start column-gap-20 px-0">
              <label className="fw-bold">Tabela Portabilidade</label>
              <CustomSelect
                isClearable={false}
                options={listProdutosPortabilidade ?? []}
                value={portabilidadeSelecionada}
                onChange={(e) => {
                  setPortabilidadeSelecionada(e)
                  dispatch(
                    atendimentoActions.selectPortabilidadeProdutoPortabilidade(
                      index,
                      simulacao.matricula,
                      indexContrato,
                      listProdutosPortabilidade?.findIndex(
                        (produtoPortabilidade) =>
                          produtoPortabilidade.codigo === e
                      ) ?? 0
                    )
                  )
                }}
                accessorLabel="descricao"
                accessorValue="codigo"
                width={350}
              />
            </div>
          )}

          {clienteElegivel && (
            <div className="d-flex w-100">
              <div className="d-flex flex-column align-items-start column-gap-20 w-80">
                <label className="fw-bold">Tabela Refin</label>
                <CustomSelect
                  isClearable={false}
                  options={
                    listProdutosPortabilidade?.[
                      indexPortabilidadeSelecionada ?? 0
                    ].produtosAssociados
                  }
                  value={refinPortabilidadeSelecionado}
                  onChange={(e) => {
                    setRefinPortabilidadeSelecionado(e)
                    dispatch(
                      atendimentoActions.selectPortabilidadeProdutoAssociado(
                        index,
                        simulacao.matricula,
                        indexContrato,
                        indexPortabilidadeSelecionada ?? 0,
                        listProdutosPortabilidade?.[
                          indexPortabilidadeSelecionada ?? 0
                        ].produtosAssociados?.findIndex(
                          (produtoAssociado) => produtoAssociado.codigo === e
                        )
                      )
                    )
                  }}
                  accessorLabel="descricao"
                  accessorValue="codigo"
                  width={350}
                />
              </div>
              {clienteElegivel && (
                <div className="d-flex flex-1 flex-column align-items-start column-gap-20 w-100">
                  <label className="fw-bold">Prazo</label>
                  <CustomSelect
                    isClearable={false}
                    options={
                      listProdutosPortabilidade?.[
                        indexPortabilidadeSelecionada ?? 0
                      ]?.produtosAssociados?.[
                        indexRefinPortabilidadeSelecionado ?? 0
                      ]?.listParcelas.map((parcela) => ({
                        value: parcela,
                        label: `${parcela}`
                      })) ?? []
                    }
                    value={
                      parcelasSelected && String(parcelasSelected) !== '0'
                        ? parcelasSelected
                        : listProdutosPortabilidade?.[
                            indexPortabilidadeSelecionada ?? 0
                          ]?.produtosAssociados?.[
                            indexRefinPortabilidadeSelecionado ?? 0
                          ]?.listParcelas?.[0]
                    }
                    accessorLabel="label"
                    accessorValue="value"
                  />
                </div>
              )}

              {clienteElegivel && (
                <div className="d-flex flex-1 flex-column align-items-start justify-content-center column-gap-20 w-100">
                  <label className="fw-bold">Troco liberado</label>
                  <span className="valor-liberado">
                    {`R$ ${maskMoney(editValues.valorTotalFinanciado)}`}
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
        {clienteElegivel && (
          <div className="d-flex justify-content-center w-100">
            {' '}
            <div className="container-acoes">
              <CustomButton
                className="btn-recalcular"
                onClick={() => handleRecalculoClick()}
                isDisabled={loadingRecalculo}
              >
                {loadingRecalculo ? (
                  <LoadingBasic mainText={'Aguarde'} />
                ) : (
                  'Recalcular'
                )}
              </CustomButton>
            </div>
          </div>
        )}
        {erro && (
          <ErrorCardContratoRefinItau>{erro}</ErrorCardContratoRefinItau>
        )}
      </div>
      <div className="mt-2">
        {clienteElegivel && id && (
          <CheckboxContrato
            checked={isChecked}
            onChange={() => {
              onCheck()
            }}
          />
        )}
      </div>
    </ContentCardContratoRefinItau>
  )
}

import React, { useEffect, useState } from 'react'
import { Col, Row, FormGroup, Label, Card } from 'reactstrap'
import {
  ContentCardContratoRefinItau,
  CustomRadioButton,
  ParcelaItem
} from '../style'
import { ListaParcela, ProdutoSimulacao } from 'models/types'
import CustomSelect from 'components/Select'
import { maskMoney, maskNumber } from 'util/masks'
import InputCustom from 'components/Inputs'
import {
  setInfosConvenioDadosCrefaz,
  setInfosCrefaz
} from 'store/modules/atendimento/actions'
import useRedux from 'hooks/useRedux'

interface DetalhamentoEmprestimoCrefazProps {
  index: number
  simulacao: ProdutoSimulacao
  produtoId: number
}

export default function DetalhamentoEmprestimoCrefaz({
  simulacao,
  index,
  produtoId
}: DetalhamentoEmprestimoCrefazProps) {
  const [produtoIndex, setProdutoIndex] = useState<number | undefined>()
  const [convenioIndex, setConvenioIndex] = useState<number | undefined>()
  const [valoresSolicitados, setValoresSolicitados] = useState(() => {
    return simulacao.simulacoesCrefaz?.[
      produtoIndex ?? 0
    ]?.empresasConveniadas?.[0].listaValoresLiberados
      .map((item) => {
        return {
          valor: item?.valor || 0,
          descricao: maskMoney(item?.valor?.toFixed(2))
        }
      })
      .sort((a, b) => b.valor - a.valor)
  })
  const [prazos, setPrazos] = useState<ListaParcela[]>([])

  useEffect(() => {
    const newProdutoIndex = simulacao.simulacoesCrefaz?.findIndex(
      (simulacao) => simulacao.produtoId === produtoId
    )

    setProdutoIndex(newProdutoIndex)

    const newConvenioIndex = simulacao.simulacoesCrefaz?.[
      newProdutoIndex ?? 0
    ]?.empresasConveniadas.findIndex(
      (s) =>
        s.convenioId ===
        simulacao.simulacoesCrefaz?.[produtoIndex ?? 0]?.selecionados.convenioId
    )

    setConvenioIndex(newConvenioIndex)

    const newValoresSolicitados =
      simulacao.simulacoesCrefaz?.[newProdutoIndex ?? 0]?.empresasConveniadas?.[
        newConvenioIndex ?? 0
      ]?.listaValoresLiberados
        .map((item) => {
          return {
            valor: item?.valor || 0,
            descricao: maskMoney(item?.valor?.toFixed(2))
          }
        })
        .sort((a, b) => b.valor - a.valor) ?? []

    setValoresSolicitados(newValoresSolicitados)

    const newPrazos =
      simulacao.simulacoesCrefaz?.[newProdutoIndex ?? 0].empresasConveniadas?.[
        newConvenioIndex ?? 0
      ]?.listaValoresLiberados.find(
        (valorLiberado) =>
          valorLiberado.valor ===
          simulacao.simulacoesCrefaz?.[produtoIndex ?? 0].selecionados
            .valorSolicitado
      )?.listaParcelas ?? []

    const sortedPrazos = newPrazos.sort((a, b) => b.prazo - a.prazo)
    setPrazos(sortedPrazos)
    /* eslint-disable */
  }, [
    simulacao,
    produtoId,
    produtoIndex,
    simulacao.simulacoesCrefaz?.[produtoIndex ?? 0].selecionados
      .valorSolicitado,
    simulacao.simulacoesCrefaz?.[produtoIndex ?? 0].selecionados.convenioId
  ])
  /* eslint-enable */

  const { dispatch } = useRedux()

  return (
    <ContentCardContratoRefinItau>
      <div className="d-flex flex-column w-100">
        <Row>
          <Col lg={6}>
            <Label className="fw-bolder">Empresa Conveniada</Label>
            <CustomSelect
              isClearable={false}
              options={
                simulacao.simulacoesCrefaz?.[produtoIndex ?? 0]
                  .empresasConveniadas ?? []
              }
              value={
                simulacao.simulacoesCrefaz?.[produtoIndex ?? 0].selecionados
                  .convenioId ?? ''
              }
              onChange={(e) => {
                dispatch(
                  setInfosCrefaz({
                    value: e,
                    field: 'convenioId',
                    index: index,
                    indexProduto: produtoIndex ?? 0
                  })
                )
              }}
              accessorLabel="descricao"
              accessorValue="convenioId"
            />
          </Col>
          <Col lg={6}>
            <Label className="fw-bolder">Valor Solicitado</Label>
            <CustomSelect
              isClearable={false}
              options={valoresSolicitados || []}
              value={
                simulacao.simulacoesCrefaz?.[produtoIndex ?? 0].selecionados
                  .valorSolicitado || ''
              }
              onChange={(e) => {
                dispatch(
                  setInfosCrefaz({
                    value: e,
                    field: 'valorSolicitado',
                    index: index,
                    indexProduto: produtoIndex ?? 0
                  })
                )
                const newPrazos =
                  simulacao.simulacoesCrefaz?.[
                    produtoIndex ?? 0
                  ].empresasConveniadas?.[
                    convenioIndex ?? 0
                  ].listaValoresLiberados.find(
                    (valorLiberado) =>
                      valorLiberado.valor ===
                      simulacao.simulacoesCrefaz?.[produtoIndex ?? 0]
                        .selecionados.valorSolicitado
                  )?.listaParcelas ?? []

                const maiorPrazo = newPrazos.reduce(
                  (max, item) => (item.prazo > max.prazo ? item : max),
                  newPrazos[0]
                )

                dispatch(
                  setInfosCrefaz({
                    value: maiorPrazo.prazo,
                    field: 'prazo',
                    index: index,
                    indexProduto: produtoIndex ?? 0
                  })
                )
                dispatch(
                  setInfosCrefaz({
                    value: maiorPrazo.valor,
                    field: 'valorParcela',
                    index: index,
                    indexProduto: produtoIndex ?? 0
                  })
                )
              }}
              accessorLabel="descricao"
              accessorValue="valor"
            />
          </Col>
        </Row>
        <Row className="row-gap-3">
          <Col xl={6}>
            {simulacao.simulacoesCrefaz?.[
              produtoIndex ?? 0
            ].empresasConveniadas[convenioIndex ?? 0]?.convenioDados.map(
              (dado, i) => {
                return (
                  <Row key={i}>
                    <Col>
                      <Label className="fw-bolder">{dado.descricao} *</Label>
                      <InputCustom
                        type="text"
                        value={
                          simulacao.simulacoesCrefaz?.[produtoIndex ?? 0]
                            .selecionados.convenioDados[i]?.valor
                        }
                        onChange={(e) => {
                          dispatch(
                            setInfosConvenioDadosCrefaz({
                              value: maskNumber(e.target.value),
                              field: 'valor',
                              index: index,
                              indexProduto: produtoIndex ?? 0,
                              indexConvenioDado: i
                            })
                          )
                        }}
                        placeholder="Digite"
                      />
                    </Col>
                  </Row>
                )
              }
            )}
          </Col>
          <Col xl={6}>
            <Card className="p-3 shadow-sm">
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label className="fw-bolder">Prazo</Label>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label className="fw-bolder">Valor da Parcela</Label>
                  </FormGroup>
                </Col>
                {prazos.map((parcela, i) => (
                  <React.Fragment key={i}>
                    <Col className="d-flex align-items-center" md={6}>
                      <FormGroup>
                        <ParcelaItem>
                          <CustomRadioButton
                            type="radio"
                            name={`prazo-${produtoIndex}-${0}-${i}`}
                            value={parcela.prazo}
                            checked={
                              simulacao.simulacoesCrefaz?.[produtoIndex ?? 0]
                                .selecionados.prazo === parcela.prazo
                            }
                            onClick={() => {
                              dispatch(
                                setInfosCrefaz({
                                  value: parcela.prazo,
                                  field: 'prazo',
                                  index: index,
                                  indexProduto: produtoIndex ?? 0
                                })
                              )
                              dispatch(
                                setInfosCrefaz({
                                  value: parcela.valor,
                                  field: 'valorParcela',
                                  index: index,
                                  indexProduto: produtoIndex ?? 0
                                })
                              )
                            }}
                          />
                          <Label
                            className="mb-0"
                            htmlFor={`prazo-${produtoIndex}-${0}-${i}`}
                          >
                            {parcela.prazo} meses
                          </Label>
                        </ParcelaItem>
                      </FormGroup>
                    </Col>
                    <Col className="d-flex align-items-center" md={6}>
                      <FormGroup>
                        <ParcelaItem>
                          <span>R$ {parcela.valor.toFixed(2)}</span>
                        </ParcelaItem>
                      </FormGroup>
                    </Col>
                  </React.Fragment>
                ))}
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </ContentCardContratoRefinItau>
  )
}

export const EnumServicos = {
  1: 'Empréstimo Consignado Novo',
  2: 'Refin Itaú',
  3: 'Portabilidade',
  4: 'Crédito Conta',
  5: 'Saque Complementar - Consignado',
  6: 'Cartão de Crédito Consignado',
  7: 'Cartão Benefício',
  8: 'Saque Complementar - Benefício',
  9: 'FGTS',
  10: 'Empréstimo Garantia Imóvel',
  11: 'Empréstimo Garantia Veículo',
  12: 'Seguro SINDNAPI',
  13: 'Empréstimo Crefaz'
}

export const EnumProdutos = {
  10: 'Empréstimo Consignado Novo',
  40: 'Refin',
  60: 'Cartão de Crédito Consignado',
  70: 'Cartão Benefício',
  80: 'Saque Complementar - Consignado',
  90: 'Saque Complementar - Benefício',
  94: 'SINDNAPI',
  95: 'FGTS',
  115: 'Portabilidade'
}

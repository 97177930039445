import { Paginacao } from 'models/types'
import { TipoArquivo } from 'models/atendimento/types'

/* eslint-disable @typescript-eslint/no-explicit-any */
export enum TYPES {
  SET_FILTROS = '@@producao/SET_FILTROS',
  CLEAN_FILTROS = '@@producao/CLEAN_FILTROS',
  SET_USUARIOS_OPTIONS = '@@producao/SET_USUARIOS_OPTIONS',
  SET_EQUIPES_OPTIONS = '@@producao/SET_EQUIPES_OPTIONS',
  SET_TABELA_ACOMPANHAMENTO_PRODUCAO = '@@producao/SET_TABELA_ACOMPANHAMENTO_PRODUCAO',
  CLEAN_TABELA_ACOMPANHAMENTO_PRODUCAO = '@@producao/CLEAN_TABELA_ACOMPANHAMENTO_PRODUCAO',
  SET_PAGINATION = '@@producao/SET_PAGINATION',
  SET_MODAL_PENDENCIAS_ANEXO_CREFAZ_FIELDS = '@@producao/SET_MODAL_PENDENCIAS_ANEXO_CREFAZ_FIELDS',
  CLEAN_MODAL_PENDENCIAS_ANEXO_CREFAZ = '@@producao/CLEAN_MODAL_PENDENCIAS_ANEXO_CREFAZ',

  FIND_SAGA = '@@producao/FIND_SAGA',
  GET_EQUIPES_SAGA = '@@producao/GET_EQUIPES_SAGA',
  GET_USUARIOS_SAGA = '@@producao/GET_USUARIOS_SAGA',
  GET_ANEXOS_SAGA = '@@producao/GET_ANEXOS_SAGA',
  GET_TIPOS_ARQUIVO_CREFAZ_SAGA = '@@producao/GET_TIPOS_ARQUIVO_CREFAZ_SAGA',
  SALVAR_ANEXO_SAGA = '@@producao/SALVAR_ANEXO_SAGA',
  VISUALIZAR_ANEXO_SAGA_DISPATCH = '@@producao/VISUALIZAR_ANEXO_SAGA_DISPATCH',
  DOWNLOAD_ANEXO_SAGA_DISPATCH = '@@producao/DOWNLOAD_ANEXO_SAGA_DISPATCH',
  DELETAR_ANEXO_SAGA_DISPATCH = '@@producao/DELETAR_ANEXO_SAGA_DISPATCH'
}

export interface PRODUCAO {
  filtros: FiltrosProducao
  pagination: Paginacao
  tabelaAcompanhamentoProducao: AcompanhamentoProducao[]
  usuarios: Usuario[]
  equipes: Equipe[]
  modalPendenciasAnexoCrefaz: ModalPendenciasAnexoCrefaz
}

interface FiltrosProducao {
  dataInicio: string
  dataFim: string
  equipeId: string
  digitadorId: string
  adesao: string
  cpf: string
}

export interface AcompanhamentoProducao {
  id: string
  cpf: string
  nomeCliente: string
  adesao: string
  dataCriacao: string
  valorTotal: number
  quantidadeParcelas: number
  valorParcela: number
  usuarioInstituicao: any
  status: string
  banco: string
  numeroMatricula: string
  equipe: string
  descricaoTipoOperacao: string
  nomeDigitador: string
  produto: number
  possuiSeguro: boolean
  motivoPendencia: string
}

export interface Usuario {
  id: string
  nome: string
}

export interface Equipe {
  id: string
  nome: string
}

export interface AcompanhamentoProducaoFieldsTabela
  extends Omit<
    AcompanhamentoProducao,
    'produto' | 'valorParcela' | 'valorTotal'
  > {
  produto: string
  valorParcela: string
  valorTotal: string
}

export interface ModalPendenciasAnexoCrefaz {
  cpf: string
  propostaId: string
  isOpen: boolean
  register: Register
  tiposArquivo: TipoArquivo[]
  anexos: Anexo[]
  paginacaoAnexos: Paginacao
}

interface Register {
  tipoArquivo: string
  uploadedFile: File | null
}

export interface Anexo {
  id: string
  nomeArquivo: string
  descricaoTipoArquivo: string
  dataCriacao: string
}

import React, { useEffect, useState } from 'react'
import CustomSelect from 'components/Select'
import SimpleCard from 'components/SimpleCard'
import { FiBook } from 'react-icons/fi'
import { Col, Row } from 'reactstrap'
import { SimpleTitleCard, CustomButton } from 'styles/global'
import CustomTable from 'components/CustomTable'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import useRedux from 'hooks/useRedux'
import * as carteiraActions from 'store/modules/backoffice/carteira/actions'
import { COLUMNS } from '../constants/columns'
import { CARTEIRA } from 'store/modules/backoffice/carteira/types'
import ModalConfirmacao from 'components/ModalConfirmacao'

type Fields = keyof CARTEIRA['search']

const Search = () => {
  const { search, carteiras, pagination, centrosCustos } = useSelector<
    ApplicationState,
    CARTEIRA
  >((state) => state.carteira)

  const [isModalConfirmacaoOpen, setIsModalConfirmacaoOpen] = useState(false)

  const { dispatch } = useRedux()

  function onChange(value: string, field: Fields) {
    dispatch(carteiraActions.setPesquisa(value, field))
  }

  function aplicarFiltros() {
    dispatch(carteiraActions.setPaginacao({ ...pagination, page: 0 }))
    dispatch(carteiraActions.getAllCarteirasSaga())
  }

  function setPage(value: number) {
    dispatch(carteiraActions.setPaginacao({ ...pagination, page: value }))
    dispatch(carteiraActions.getAllCarteirasSaga())
  }

  function setPageSize(value: number) {
    dispatch(
      carteiraActions.setPaginacao({ ...pagination, page: 0, pageSize: value })
    )
    dispatch(carteiraActions.getAllCarteirasSaga())
  }

  useEffect(() => {
    if (!centrosCustos || centrosCustos.length === 0) {
      dispatch(carteiraActions.getCentroCustoSaga())
    }
  }, [dispatch, centrosCustos])

  useEffect(() => {
    dispatch(carteiraActions.getAllCarteirasSaga())
  }, [dispatch])

  return (
    <SimpleCard>
      <div className="container-fluid d-flex flex-column row-gap-10">
        <Row>
          <Col md={6}>
            <SimpleTitleCard>
              <FiBook className="icon" />
              Gerenciamento de carteira
            </SimpleTitleCard>
          </Col>
          <Col className="d-flex justify-content-end" md={6}></Col>
        </Row>
        <Row className="d-flex row-gap-15">
          <Col md={4}>
            <label className="label-12">Ativo</label>
            <CustomSelect
              options={[
                { label: 'Sim', value: 'Sim' },
                { label: 'Não', value: 'Nao' }
              ]}
              placeholder="Filtrar por"
              onChange={(e) => {
                onChange(e, 'ativo')
              }}
              value={search.ativo}
              accessorLabel="label"
              accessorValue="value"
            />
          </Col>
          <Col md={4}>
            <label className="label-12">Clientes Sem Consultores</label>
            <CustomSelect
              options={[
                { label: 'Sim', value: 'Sim' },
                { label: 'Não', value: 'Nao' }
              ]}
              placeholder="Filtrar por"
              onChange={(e) => {
                onChange(e, 'clientesSemConsultores')
              }}
              value={search.clientesSemConsultores}
              accessorLabel="label"
              accessorValue="value"
            />
          </Col>

          <Col md={4}>
            <label className="label-12">Centro de custo</label>
            <CustomSelect
              options={centrosCustos}
              placeholder="Filtrar por"
              onChange={(e) => {
                onChange(e, 'centroCustoId')
              }}
              value={search.centroCustoId}
              accessorLabel="descricao"
              accessorValue="id"
            />
          </Col>
        </Row>
        <Row>
          <Col className="d-flex gap-3 justify-content-between">
            <CustomButton
              onClick={() => {
                aplicarFiltros()
              }}
              background="var(--roxo-riber)"
            >
              Pesquisar
            </CustomButton>
            <CustomButton
              onClick={() => setIsModalConfirmacaoOpen(true)}
              minWidth="200px"
            >
              Atualizar oportunidades
            </CustomButton>
          </Col>
        </Row>
        <Row>
          <CustomTable
            data={carteiras}
            columns={COLUMNS}
            paginationMode="server"
            rowCount={pagination.totalRegistros}
            page={pagination.page}
            pageSize={pagination.pageSize}
            setPage={(e) => setPage(e)}
            setPageSize={(e) => setPageSize(e)}
            autoHeight
          />
        </Row>
      </div>
      <ModalConfirmacao
        isOpen={isModalConfirmacaoOpen}
        toggle={(e) => setIsModalConfirmacaoOpen(e)}
        onConfirm={() => dispatch(carteiraActions.atualizaCarteirasSaga())}
      >
        Deseja atualizar as oportunidades da carteira?
      </ModalConfirmacao>
    </SimpleCard>
  )
}

export default Search

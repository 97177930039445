import { Referencia } from 'models/atendimento/types'

export const filterReferenciasPreenchidas = (referencias: Referencia[]) => {
  return (
    referencias?.filter((referencia) => {
      const { nome, ddd, numero, grauReferenciaCrefazId } = referencia
      return nome || ddd || numero || grauReferenciaCrefazId
    }) || []
  )
}

export const validateAndConvertInputValue = (
  inputValue?: number | string | null
): string | number | undefined => {
  if (inputValue === '' || inputValue === null || inputValue === undefined) {
    return ''
  }
  if (!isNaN(Number(inputValue))) {
    return inputValue
  }
  return inputValue
}

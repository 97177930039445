import React, { useEffect } from 'react'
import SimpleCard from 'components/SimpleCard'
import { Col, Row } from 'reactstrap'
import { CustomButton, SimpleTitleCard } from 'styles/global'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { CREDENCIAIS } from 'store/modules/backoffice/credenciais/types'
import * as credenciaisActions from 'store/modules/backoffice/credenciais/actions'
import useRedux from 'hooks/useRedux'
import { toast } from 'react-toastify'
import CustomSelect from 'components/Select'

const Crefaz = () => {
  const { crefaz, usuariosInstituicaoCrefaz } = useSelector<
    ApplicationState,
    CREDENCIAIS
  >((state) => state.credenciais)
  const { dispatch } = useRedux()

  type LoginFields = keyof CREDENCIAIS['crefaz']
  function onChange(value: string, field: LoginFields) {
    dispatch(credenciaisActions.setCredencialCrefazFields(value, field))
  }

  const onSave = () => {
    if (crefaz.usuarioInstituicao) {
      dispatch(credenciaisActions.salvarCredencialCrefazSaga())
    } else {
      toast.warn('É obrigatório selecionar um usuário antes de salvar!')
    }
  }

  useEffect(() => {
    dispatch(credenciaisActions.getUsuariosInstituicaoCrefazSaga())
  }, [dispatch])

  return (
    <SimpleCard>
      <SimpleTitleCard>Usuário Crefaz</SimpleTitleCard>
      <Row className="d-flex flex-column align-items-center gap-3">
        <Col md={8}>
          <label className="label-12">Usuário</label>
          <CustomSelect
            options={usuariosInstituicaoCrefaz}
            placeholder="Selecione o usuário"
            onChange={(e) => onChange(e, 'usuarioInstituicao')}
            value={crefaz.usuarioInstituicao}
            accessorLabel="usuarioInstituicao"
            accessorValue="usuarioInstituicao"
            isClearable={false}
          />
        </Col>
        <Col md={4} className="d-flex justify-content-center">
          <CustomButton background="#662d90" onClick={onSave}>
            Salvar
          </CustomButton>
        </Col>
      </Row>
    </SimpleCard>
  )
}

export default Crefaz

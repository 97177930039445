import InputCustom from 'components/Inputs'
import useRedux from 'hooks/useRedux'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Col, Row } from 'reactstrap'
import { ApplicationState } from 'store'
import { ATENDIMENTO } from 'store/modules/atendimento/types'
import * as atendimentoActions from 'store/modules/atendimento/actions'
import CustomSelect from 'components/Select'
import { maskMoney } from 'util/masks'
import { FiTrash } from 'react-icons/fi'
import { Matricula } from 'models/atendimento/types'
import ModalExclusao from 'components/ModalExclusao'

interface ServidoresProps {
  index: number
  indexPerfil: number
}

const Servidores = ({ index, indexPerfil }: ServidoresProps) => {
  const { atendimentosAbertos, perfis } = useSelector<
    ApplicationState,
    ATENDIMENTO
  >((state) => state.atendimento)

  const { dispatch } = useRedux()

  const [show, setShow] = useState(false)

  type Fields = keyof Matricula
  function onChange(value: string | number, field: Fields) {
    const matriculas = [
      ...(atendimentosAbertos[index].tabInformacoes.infoCliente.matriculas ||
        [])
    ]
    matriculas[indexPerfil] = {
      ...matriculas[indexPerfil],
      [field]: value
    }
    dispatch(
      atendimentoActions.setInformacoesField(matriculas, 'matriculas', index)
    )
  }

  useEffect(() => {
    dispatch(atendimentoActions.getConveniosInfoSaga(index))
  }, [dispatch, index])

  return (
    <div className="d-flex flex-column row-gap-15">
      <div className="container-fluid">
        <Row>
          <Col md={4}>
            <label className="label-12">Perfil*</label>
            <CustomSelect
              options={perfis}
              placeholder="Selecione"
              onChange={(e) => {
                onChange(e, 'tipoPerfilId')
              }}
              value={
                atendimentosAbertos[index].tabInformacoes.infoCliente
                  .matriculas?.[indexPerfil]?.tipoPerfilId || ''
              }
              accessorLabel="descricao"
              accessorValue="id"
              invalid={
                atendimentosAbertos[index].tabInformacoes.invalids.matriculas[
                  indexPerfil
                ]?.tipoPerfilId
              }
              disabled
            />
          </Col>
          <Col md={4}>
            <label className="label-12">Matrícula*</label>
            <InputCustom
              name="matricula"
              type="text"
              onChange={(e) => {
                onChange(e.target.value, 'numeroMatricula')
              }}
              placeholder="Digite aqui"
              value={
                atendimentosAbertos[index].tabInformacoes.infoCliente
                  .matriculas?.[indexPerfil]?.numeroMatricula || ''
              }
              invalid={
                atendimentosAbertos[index].tabInformacoes.invalids.matriculas[
                  indexPerfil
                ]?.numeroMatricula
              }
              maxLength={15}
            />
          </Col>
          <Col md={4}>
            <label className="label-12">Convênio*</label>
            <CustomSelect
              options={
                atendimentosAbertos[index].tabInformacoes.optionsSelect
                  .convenios
              }
              placeholder="Selecione"
              onChange={(e) => {
                onChange(e, 'convenioId')
              }}
              value={
                atendimentosAbertos[index].tabInformacoes.infoCliente
                  .matriculas?.[indexPerfil]?.convenioId || ''
              }
              accessorLabel="descricao"
              accessorValue="id"
              invalid={
                atendimentosAbertos[index].tabInformacoes.invalids.matriculas[
                  indexPerfil
                ]?.convenioId
              }
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <label className="label-12">
              Margem consignável de empréstimo*
            </label>
            <InputCustom
              name="margemDisponivel"
              type="text"
              onChange={(e) => {
                onChange(maskMoney(e.target.value), 'margemDisponivel')
              }}
              placeholder="Digite aqui"
              value={
                maskMoney(
                  atendimentosAbertos[index].tabInformacoes.infoCliente
                    .matriculas?.[indexPerfil]?.margemDisponivel
                ) || ''
              }
              invalid={
                atendimentosAbertos[index].tabInformacoes.invalids.matriculas[
                  indexPerfil
                ]?.margemDisponivel
              }
              maxLength={14}
            />
          </Col>
          <Col md={4}>
            <label className="label-12">Margem consignável de cartão*</label>
            <InputCustom
              name="margemCartaoDisponivel"
              type="text"
              onChange={(e) => {
                onChange(maskMoney(e.target.value), 'margemCartaoDisponivel')
              }}
              placeholder="Digite aqui"
              value={
                maskMoney(
                  atendimentosAbertos[index].tabInformacoes.infoCliente
                    .matriculas?.[indexPerfil]?.margemCartaoDisponivel
                ) || ''
              }
              invalid={
                atendimentosAbertos[index].tabInformacoes.invalids.matriculas[
                  indexPerfil
                ]?.margemCartaoDisponivel
              }
              maxLength={14}
            />
          </Col>
          <Col md={4}>
            <label className="label-12">Margem cartão benefício*</label>
            <InputCustom
              name="margemCartaoBeneficio"
              type="text"
              onChange={(e) => {
                onChange(maskMoney(e.target.value), 'margemCartaoBeneficio')
              }}
              placeholder="Digite aqui"
              value={
                maskMoney(
                  atendimentosAbertos[index].tabInformacoes.infoCliente
                    .matriculas?.[indexPerfil]?.margemCartaoBeneficio
                ) || ''
              }
              invalid={
                atendimentosAbertos[index].tabInformacoes.invalids.matriculas[
                  indexPerfil
                ]?.margemCartaoBeneficio
              }
              maxLength={14}
            />
          </Col>
        </Row>
      </div>
      <div className="container-fluid">
        <Row>
          <Row>
            <Col className="d-flex justify-content-end">
              <FiTrash className="icon-trash" onClick={() => setShow(true)} />
            </Col>
          </Row>
        </Row>
      </div>
      <ModalExclusao
        isOpen={show}
        toggle={(e) => setShow(e)}
        onRemove={() => {
          dispatch(atendimentoActions.removerPerfil(indexPerfil, index))
        }}
      />
    </div>
  )
}

export { Servidores }

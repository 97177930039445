import { CARTEIRA, CentroCusto } from './types'
import { ActiveTab } from './types'
import store from 'store'
import { Carteiras, DetailsCarteira, TYPES } from './types'
import { Paginacao } from 'models/types'

export const setActiveTab = (value: ActiveTab) => ({
  type: TYPES.SET_ACTIVE_TAB,
  value
})

export const getAllCarteirasSaga = () => ({
  type: TYPES.GET_ALL_CARTEIRAS_SAGA
})

export const setAllCarteiras = (value: Carteiras[]) => ({
  type: TYPES.SET_ALL_CARTEIRAS,
  value
})

export const setDetailsCarteiras = (value: DetailsCarteira[]) => ({
  type: TYPES.SET_DETAILS_CARTEIRAS,
  value
})

export const setVisualizacao = (value: boolean) => ({
  type: TYPES.SET_VISUALIZACAO,
  value
})

export const visualizarCarteiraDispatchSaga = (lojaId: string) =>
  store.dispatch({
    type: TYPES.VISUALIZAR_CARTEIRA_DISPATCH_SAGA,
    lojaId
  })

export const editarCarteiraDispatchSaga = (lojaId: string) =>
  store.dispatch({
    type: TYPES.EDITAR_CARTEIRA_DISPATCH_SAGA,
    lojaId
  })

export const getClientesPorConsultorSaga = (value: {
  lojaId: string
  consultorId: string
  nomeConsultor?: string
}) => ({
  type: TYPES.GET_CLIENTES_POR_CONSULTOR_SAGA,
  value
})

export const getClientesSemConsultor = (value: { lojaId: string }) => ({
  type: TYPES.GET_CLIENTES_SEM_CONSULTOR_SAGA,
  value
})

export const getClientesPorLojaDispachSaga = (lojaId: string) =>
  store.dispatch({
    type: TYPES.GET_CLIENTES_POR_LOJA_DISPATCH_SAGA,
    lojaId
  })

export const dividirCarteiraConsultorSaga = () => ({
  type: TYPES.DIVIDIR_CARTEIRA_CONSULTOR_SAGA
})

export const setPesquisa = (value: string | number | null, field: string) => ({
  type: TYPES.SET_PESQUISA,
  value,
  field
})

export const setPaginacao = (value: Paginacao) => ({
  type: TYPES.SET_PAGINATION,
  value
})

export const transferirCarteiraConsultor = () => ({
  type: TYPES.TRANSFERIR_CARTEIRA_CONSULTOR_SAGA
})

export const transferirCarteiraLojaSaga = () => ({
  type: TYPES.TRANSFERIR_CARTEIRA_LOJA_SAGA
})

export const setIdsTransferencia = (value: string[]) => ({
  type: TYPES.SET_IDS_TRANSFERENCIA,
  value
})

export const getIdAndNameDispatchSaga = (name: string, id: string) =>
  store.dispatch({
    type: TYPES.GET_ID_NAME_DISPATCH_SAGA,
    name,
    id
  })

export const setIdAndName = (name: string, id: string) => ({
  type: TYPES.SET_ID_NAME,
  name,
  id
})

export const getConsultorDispatchSaga = (name: string, id: string) =>
  store.dispatch({
    type: TYPES.GET_CONSULTOR_DISPATCH_SAGA,
    name,
    id
  })

export const setConsultor = (name: string, id: string) => ({
  type: TYPES.SET_CONSULTOR,
  name,
  id
})

export const cleanSearch = () => ({
  type: TYPES.CLEAN_SEARCH
})

export const setOpenClientNoConsultantsModal = (value: boolean) => ({
  type: TYPES.SET_OPEN_CLIENT_NO_CONSULTANTS_MODAL,
  value
})

export const setClientNoConsultantsModal = (
  value: CARTEIRA['clientNoConsultantsModal']
) => ({
  type: TYPES.SET_CLIENT_NO_CONSULTANTS_MODAL,
  value
})

export const cleanClientNoConsultantsModal = () => ({
  type: TYPES.CLEAN_CLIENT_NO_CONSULTANTS_MODAL
})

export const setOpenConsultantsListModal = (value: boolean) => ({
  type: TYPES.SET_OPEN_CONSULTANTS_LIST_MODAL,
  value
})

export const setConsultantsListModal = (
  value: CARTEIRA['consultantsListModal']
) => ({
  type: TYPES.SET_CONSULTANTS_LIST_MODAL,
  value
})

export const cleanConsultantsListModal = () => ({
  type: TYPES.CLEAN_CONSULTANTS_LIST_MODAL
})

export const setOpenShopListModal = (value: boolean) => ({
  type: TYPES.SET_OPEN_SHOP_LIST_MODAL,
  value
})

export const setShopListModal = (value: CARTEIRA['shopListModal']) => ({
  type: TYPES.SET_SHOP_LIST_MODAL,
  value
})

export const cleanShopListModal = () => ({
  type: TYPES.CLEAN_SHOP_LIST_MODAL
})

export const atualizaCarteirasSaga = () => ({
  type: TYPES.ATUALIZA_CARTEIRAS_SAGA
})

export const getCentroCustoSaga = () => ({
  type: TYPES.GET_CENTRO_CUSTOS_SAGA
})

export const setCentroCustos = (centros: CentroCusto[]) => ({
  type: TYPES.SET_CENTRO_CUSTOS,
  value: centros
})

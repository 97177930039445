import styled, { css } from 'styled-components'
import checkCircle from 'assets/images/check-circle.png'
import uncheckCircle from 'assets/images/uncheck-circle.png'
import { Modal } from 'reactstrap'
import { ModalBody, ModalFooter } from 'reactstrap'
import { CustomButton } from 'styles/global'

export const Tab = styled.li`
  font-family: 'Manrope', serif;
  color: #662d91 !important;
  font-weight: 700;
  display: flex;
  align-items: center;
  column-gap: 9px;
  padding: 0.5rem 1rem;

  & span {
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    min-width: 110px;
    max-width: 150px;
    cursor: pointer;
  }

  & .icon {
    color: #bfbfbf;
    width: 20px;
    height: 20px;
    margin-right: 9px;
  }

  & .close {
    color: #bfbfbf;
    width: 12px;
    height: 12px;
    text-align: right;
  }

  & a {
    align-items: center;
    color: #353535;
    display: flex;
    font-family: 'Open Sans', serif;
    font-size: 14px;
    font-weight: 400;
    justify-content: space-between;
    line-height: 24px;
    min-width: 110px;
    text-decoration: none;
  }

  & a.active {
    border-bottom: #00ab4f solid 4px;
  }
`

export const Blank = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-inline: auto;
  max-width: 350px;
  gap: 24px;

  & .icon {
    color: #662d91;
    width: 80px;
    height: 80px;
  }

  & h2 {
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
  }
`

export const Ul = styled.ul`
  border-bottom: 1px solid #cfcfcf;
`

export const BtnChamadaGreen = styled.div`
  max-width: 180px;
  width: 100%;
  white-space: nowrap;
  background: linear-gradient(264.61deg, #87df41 -9.34%, #00ab4f 101.57%);
  border-radius: 15px;
  cursor: pointer;
  padding: 15px;
  align-items: center;
  justify-content: space-between;
  display: flex;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  letter-spacing: 2px;

  & label {
    max-width: 100%;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  & .icon {
    font-size: 20px;
  }
`
export const BtnChamadaOrange = styled.div`
  max-width: 180px;
  width: 100%;
  white-space: nowrap;
  background: #f15a24;
  border-radius: 15px;
  cursor: pointer;
  padding: 15px;
  align-items: center;
  justify-content: space-between;
  display: flex;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  letter-spacing: 2px;

  & span {
    max-width: 100%;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  & .icon {
    font-size: 20px;
  }
`

export const Chamada = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: linear-gradient(43.84deg, #662d91 -5.32%, #a64dff 97.27%);
  border-radius: 5px;
  gap: 15px;

  & .info-numero {
    font-size: 20px;
  }

  & .info-duracao {
    display: flex;
    flex-direction: column;
    text-align: right;
    margin-right: 10px;
    width: auto;

    & label {
      color: #ffffff;
    }

    & span {
      text-align: end;
      font-size: 20px;
      color: #ffffff;
    }
  }
`

interface TabulacaoProps {
  active?: boolean
}

export const Tabulacao = styled.div<TabulacaoProps>`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  color: #662d91;
  cursor: pointer;
  width: auto;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 16px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.016);
  border-radius: 10px;

  ${(props) => props.active && `background: #f15a24; color: #ffffff;`}

  &:hover {
    background: #e2e2e2;
  }
`
export const BtnAgendar = styled.div`
  width: 180px;
  height: 40px;
  background: linear-gradient(264.61deg, #87df41 -9.34%, #00ab4f 101.57%);
  border-radius: 8px;
  cursor: pointer;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
  display: flex;
`

export const TabAtendimento = styled.a`
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 10px;
  color: #662d91;
  display: flex;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 22px;
  padding: 5px 16px;
  text-decoration: none;

  &:hover {
    background: #e2e2e2;
    color: #662d91;
  }

  &.active {
    background: #662d91;
    color: #ffffff;
  }

  &.active:hover {
    background: #6c3b91;
  }

  & .icon {
    height: 20px;
    width: 20px;
    margin-right: 5px;
  }
`
export const BntValidaBeneficio = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: #662d91;
`

export const ApresentacaoValores = styled.div`
  align-items: center;
  background: linear-gradient(43.84deg, #662d91 -5.32%, #a64dff 97.27%);
  border-radius: 15px;
  color: #ffffff;
  display: flex;
  padding: 20px;
  row-gap: 15px;

  & label {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
  }

  & span {
    font-size: 16px;
    line-height: 26px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
  }

  & .icon {
    width: 50px;
    height: 50px;
  }

  & .valor-contratacao {
    font-weight: 700;
    font-size: 32px;
    line-height: 32px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
  }

  @media (max-width: 992px) {
    align-items: start;
    flex-direction: column;
  }
`
export const ContentProduto = styled.div`
  align-self: stretch;
  background: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  row-gap: 20px;
  height: 100%;
  width: 100%;

  & .icon {
    color: #662d91;
    width: 30px;
    height: 30px;
    min-width: 30px;
  }

  & .matricula {
    font-size: 15px;
    font-weight: 700;
  }

  & .nome-produto {
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    color: #353535;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
  }

  & .valor-produto {
    font-weight: 700;
    font-size: 25px;
    line-height: 28px;
    color: #00ab4f;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
  }

  & .valor-produto-secondary {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #838383;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
  }

  & .mensagem-erro {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #dc3545;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
  }

  & .iconInformation {
    color: #662d91;
    width: 15px;
    height: 15px;
    min-width: 15px;
  }

  & .saque-parcelado {
    margin-top: 35px;
  }
`

export const CheckboxProduto = styled.input.attrs({ type: 'checkbox' })`
  position: relative;
  cursor: pointer;

  &:before {
    content: '';
    display: block;
    position: absolute;
    background: url(${uncheckCircle}) #fff no-repeat;
    width: 40px;
    height: 40px;
    top: -10px;
    left: -10px;
  }

  &:checked:before {
    content: '';
    display: block;
    position: absolute;
    background: url(${uncheckCircle}) #fff no-repeat;
    width: 40px;
    height: 40px;
    top: -10px;
    left: -10px;
  }

  &:checked:after {
    content: '';
    display: block;
    position: absolute;
    background: url(${checkCircle}) #fff no-repeat;
    width: 40px;
    height: 40px;
    top: -10px;
    left: -10px;
  }
`

export const CheckboxContrato = styled(CheckboxProduto)`
  &:before {
    background: url(${uncheckCircle}) #f3f3f3 no-repeat;
  }

  &:checked:before {
    background: url(${uncheckCircle}) #f3f3f3 no-repeat;
  }

  &:checked:after {
    background: url(${checkCircle}) #f3f3f3 no-repeat;
  }
`

export const ContentDetalhamentoProduto = styled.div`
  background: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  color: #474a57;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  padding: 40px 20px;
  row-gap: 40px;
  width: 100%;

  & h2 {
    font-size: 16px;
    line-height: 26px;
    text-align: center;
  }

  & .btn-plus,
  .btn-less {
    align-items: center;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.043);
    border-radius: 7px;
    color: #ffffff;
    cursor: pointer;
    display: flex;
    height: 38px;
    padding: 8px 16px;
  }

  & .btn-plus {
    background: #00ab4f;
  }

  & .btn-less {
    background: #662d91;
  }

  & .detalhamento-fgts {
    cursor: pointer;
    color: #662d91;

    &:hover {
      color: #4e2170;
      font-weight: 600;
    }

    & .icon {
      font-size: 20px;
    }
  }

  & .mensagem-erro {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #dc3545;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
  }
`
export const ContentCardOutrosProdutos = styled.div`
  background: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  row-gap: 10px;
  width: 270px;

  & .header label {
    font-size: 14px;
    line-height: 24px;
    color: #00ab4f;
  }

  & .header h2 {
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    color: #353535;
  }

  & p {
    font-size: 16px;
    line-height: 26px;
    color: #474a57;
  }

  & span {
    font-weight: 600;
    font-size: 18px;
    line-height: 16px;
    color: #00ab4f;
  }

  & .range-valores {
    color: #8e8e8e;
    font-size: 16px;
    line-height: 26px;
  }

  input[type='range'] {
    -webkit-appearance: none;
    margin-right: 15px;
    width: 200px;
    height: 7px;
    border-radius: 5px;
    background: #cfcfcf linear-gradient(to left, #87df41, #00ab4f) no-repeat;
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #ffff;
    cursor: pointer;
    box-shadow: 0 0 2px 0 #555;
    transition: background 0.3s ease-in-out;
  }

  input[type='range']::-moz-range-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #ffff;
    cursor: pointer;
    box-shadow: 0 0 2px 0 #555;
    transition: background 0.3s ease-in-out;
  }

  input[type='range']::-ms-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #ffff;
    cursor: pointer;
    box-shadow: 0 0 2px 0 #555;
    transition: background 0.3s ease-in-out;
  }

  input[type='range']::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  input[type='range']::-moz-range-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  input[type='range']::-ms-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  input[type='range']::-moz-range-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;
    background: #3071a9;
    border-radius: 1.3px;
    border: 0.2px solid #010101;
  }

  input[type='range']::-moz-range-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
  }

  input[type='range']::-ms-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
  }

  input[type='range']::-ms-fill-lower {
    background: #2a6495;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;
  }

  input[type='range']::-ms-fill-upper {
    background: #3071a9;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;
  }

  input[type='range']::-ms-thumb {
    box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;
    border: 1px solid #000000;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
  }

  input[type='range']:focus::-ms-fill-lower {
    background: #3071a9;
  }

  input[type='range']:focus::-ms-fill-upper {
    background: #367ebd;
  }
`
export const ContentCardProdutosContratados = styled.div`
  background: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  color: #474a57;
  font-size: 14px;
  padding: 40px 20px;
  row-gap: 10px;
  width: 100%;

  & label {
    color: #474a57;
    font-size: 14px;
    line-height: 24px;
  }

  & .strong-green,
  & .strong-black {
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
  }

  & .strong-green {
    color: #00ab4f;
  }

  & .strong-black {
    color: #353535;
  }

  & .icon {
    width: 32px;
    height: 32px;
  }
`
export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  position: relative;
  cursor: pointer;
  transform: scale(0.8);

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    top: -2px;
    left: -2px;
    background-color: #ffffff;
    border: solid 2px #662d91;
    border-radius: 5px;
  }
  &:checked:before {
    content: '';
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    top: -2px;
    left: -2px;
    border: solid 2px #662d91;
  }
  &:checked:after {
    content: '';
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10px;
    height: 10px;
    border-width: 0 2px 2px 0;
    border-radius: 2px;
    background-color: #662d91;
    position: absolute;
    top: 3px;
    left: 3px;
  }
`

export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
`

export const InvisibleInput = styled.input`
  border: none;
  outline: none;
  font-weight: 700;
  font-size: 25px;
  line-height: 28px;
  color: #00ab4f;
  width: 100%;
`

export const ContainerButtonsBancos = styled.div`
  display: flex;
  column-gap: 20px;
  margin-bottom: 10px;

  & input[type='radio'] {
    display: none;
  }

  & .labelBmg {
    padding: 10px 17px;
  }

  & label {
    position: relative;
    color: #662d91;
    border: 2px solid #662d91;
    border-radius: 5px;

    & img {
      height: 50px;
    }
  }

  & .labelItau {
    padding: 10px 30px;
  }

  & input[type='radio']:checked + label {
    background-color: #662d91;
    color: #fff;
  }
`

export const ContentPerfisSelecao = styled.div`
  height: 350px;
  overflow: auto;
  border: 1px solid #cfcfcf;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  font-weight: 700;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 15px;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    margin: 2px 10px;
  }

  & .itens-lista {
    color: #5e5e5e;
    cursor: pointer;
    padding: 10px 5px;

    &:hover {
      background-color: #cfcfcf;
    }
  }

  & li {
    list-style-type: none;
  }
`
export const BigBumbersConsigWrapper = styled.div`
  display: grid;
  grid-row-gap: 24px;
  grid-template-columns: repeat(12, 1fr);
  width: 100%;
  padding: 10px 0;
  margin: 0 0 10px;
  border-bottom: 1px solid #cfcfcf;

  & .bignumber {
    grid-column: span 2;
    display: flex;
    flex-direction: column;
    column-gap: 5px;
    padding: 5px;

    & label {
      color: #8e8e8e;
      font-weight: 700;
      font-size: 10px;
      text-align: center;
    }

    & span {
      color: #4e4e4e;
      font-weight: 700;
      font-size: 15px;
      text-align: center;
    }

    & .icon {
      color: #662d91;
      font-size: 25px;
      display: flex;
      align-self: center;
    }
  }
`

export const ContentCardContratoRefinItau = styled.div`
  background: #f3f3f3;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  color: #474a57;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 14px;
  padding: 8px 20px;
  row-gap: 20px;
  column-gap: 1rem;
  width: 100%;
  margin-bottom: 1rem;

  & .valor-liberado {
    font-weight: 700;
    font-size: 25px;
    line-height: 28px;
    color: #00ab4f;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
  }

  & .button-abrir-modal-agregacao {
    margin: 0;
    margin-left: 3px;
    padding: 0;
    border: none;
    background: none;
    color: inherit;
    font: inherit;
    cursor: pointer;
    outline: none;
  }

  & .button-remove-agregacao {
    margin: 0;
    margin-left: 3px;
    padding: 0;
    border: none;
    background: none;
    color: inherit;
    font: inherit;
    cursor: pointer;
    outline: none;
  }

  > div {
    gap: 8px;
  }

  & .verticalRow {
    display: flex;
    flex-direction: column;
  }

  & .flex-2 {
    flex: 2;
  }

  & .flex-1 {
    flex: 1;
  }

  & .mensagem-erro {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #dc3545;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
  }
`
export const CardErrorMessageOfertaProdutoRefin = styled.div`
  background: #f8e8e8;
  border-radius: 15px;
  border: 1px solid #8d6a6e;
  display: flex;
  gap: 3rem;
  justify-content: flex-start;
  text-align: center;
  align-items: center;
  font-size: 0.8rem;
  width: 100%;
  min-height: 2rem;
  padding: 8px 20px;
  row-gap: 20px;
`

export const ValorParcela = styled.p`
  font-weight: 700;
  font-size: 25px;
  line-height: 28px;
  color: #00ab4f;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
`

export const AccordionContainer = styled.div`
  & .accordion-body {
    padding: 1rem 0 0 0;
  }

  & .accordion-header {
    color: black;
  }

  & .accordion-button {
    background-image: 'none';
  }

  & .accordion-button:not(.collapsed) {
    color: black;
    background-color: transparent;
  }

  & .accordion-button:after {
    color: black;
  }
`

export const CardProdutoContainer = styled.div<{ isDetalhamentoOpen: boolean }>`
  width: 100%;

  ${(props) =>
    props.isDetalhamentoOpen
      ? css`
          @media (min-width: 1159px) {
            flex: 0 0 auto;
            width: 50%;
          }
        `
      : css`
          @media (min-width: 1000px) {
            width: 50%;
          }

          @media (min-width: 1260px) {
            width: 33%;
          }

          @media (min-width: 1568px) {
            width: 25%;
          }

          @media (min-width: 2560px) {
            width: 20%;
          }
        `};
`

export const ModalCoberturasStyled = styled(Modal)`
  .modal-content {
    max-height: 94vh;
  }
`

export const SpanWrap = styled.span`
  display: inline-block;
  word-break: break-word;
`

export const ContainerInfoLead = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  background-color: #ffffff;
  border-radius: 8px;
  border-top: 2px solid #662d91;
  border-bottom: 2px solid #662d91;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  .title-content {
    background-color: #f8f8f8;
    padding: 10px 10px;

    p {
      display: flex;
      margin-bottom: 0;
      font-weight: bold;
      font-size: 18px;
      gap: 0.2rem;
      color: #000;

      .icon {
        margin-right: 0.8rem;
        margin-left: 18px;
        color: #79479e;
      }
    }
  }

  .collapse-btn {
    font-size: 1.25rem;
    padding: 0;
  }

  .info-icon {
    margin-left: 8px;
    cursor: pointer;
    color: #00ab4f;
    margin-bottom: 2px;
  }

  .card-produto {
    width: 100%;
    max-width: 350px;
    min-height: 80px;
    padding: 15px;
    background-color: #f1e3ff;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    flex-grow: 1;
  }

  .Icon {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }

  .title {
    font-size: 14px;
    font-weight: bold;
  }

  .price-text {
    color: #28a745;
    font-size: 16px;
    font-weight: 700;
  }
`

export const StyledModalBody = styled(ModalBody)`
  padding: 10px;
  background-color: #f0f0f0;
  max-width: 100%;
  max-height: 400px;

  .messages-container {
    position: relative;
    height: 300px;
    overflow-y: auto;
    padding: 10px;

    /* Estilo da barra de rolagem */
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 transparent;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #c1c1c1;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }

  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    pointer-events: none;
  }

  .spinner {
    width: 40px;
    height: 40px;
    margin-bottom: 150px;
    border: 4px solid rgba(0, 128, 0, 0.5);
    border-top: 4px solid #4caf50;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export const ClientReplyBubble = styled.div`
  background: #e5e5ea;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
  color: #000;
  max-width: 70%;
  position: relative;
  align-self: flex-start;
  margin-right: auto;

  &::after {
    content: '';
    position: absolute;
    left: -10px;
    top: 10px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-right: 10px solid #e5e5ea; /* Cor da seta */
    border-bottom: 10px solid transparent;
  }
  .messageWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .messageWrapper.align-right {
    align-items: flex-end;
  }
`

export const ReceivedAt = styled.div`
  font-size: 10px;
  color: #888;
  text-align: right;
  width: 100%;
  margin-top: 3px;
  margin-right: 5px;

  .messageWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .messageWrapper:nth-child(even) {
    align-items: flex-end;
  }
`

export const DateWrapper = styled.div`
  text-align: left;
  margin-bottom: 2px;
  font-size: 10px;
  color: #888;
  width: 100%;
  margin-top: 3px;
  margin-right: 5px;
  .messageWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .messageWrapper:nth-child(even) {
    align-items: flex-end;
  }
`
export const UserMessageBubble = styled.div`
  background: #dcf8c6;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
  color: #000;
  max-width: 70%;
  position: relative;
  align-self: flex-end;
  margin-left: auto;

  &::after {
    content: '';
    position: absolute;
    right: -10px;
    top: 10px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-left: 10px solid #dcf8c6;
    border-bottom: 10px solid transparent;
  }
  .messageWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .messageWrapper.align-right {
    align-items: flex-end;
  }
`
export const MessageInputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 20px;
  background-color: #ffffff;
  width: 100%;
  margin-top: 10px;
  .inputField {
    background: transparent;
    border: none;
    outline: none;
    flex: 1;
    color: #333;
    font-size: 15px;
    font-family: Arial, sans-serif;
    padding: 10px;
  }
  .inputField::placeholder {
    color: #888;
  }
  .sendButton {
    background: transparent;
    border: none;
    color: #128c7e;
    font-size: 24px;
    cursor: pointer;
    padding: 8px;
    &:hover {
      color: #075e54;
    }
  }
`

export const StyledModalFooter = styled(ModalFooter)`
  border-top: none;
  width: 100%;
`

export const StyledCustomButton = styled(CustomButton)`
  width: 80px;
  border-radius: 15px;
  padding: 10px;
`

export const CloseButton = styled.button`
  background-color: var(--roxo-riber) !important;
  border: none;
  border-radius: 15px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 2px;
  line-height: 18px;
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
`

export const SendButton = styled.button`
  background-color: var(--verde-riber) !important;
  margin-right: 10px;
  border: none;
  border-radius: 15px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 2px;
  line-height: 18px;
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
`

export const StyledCard = styled.div`
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 16px;
  margin: 20px;
  background-color: #f8f9fa;
`

export const EmpresaConveniada = styled.div`
  font-size: 14px;
  color: #5a5a5a;
  margin-bottom: 12px;
`

export const ValorSolicitado = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #333;
`

export const InfoText = styled.div`
  font-size: 12px;
  color: red;
  margin-bottom: 10px;
`

export const InputField = styled.input`
  margin-bottom: 10px;
`

export const ParcelaItem = styled.div`
  display: flex;
  align-items: center;
`

export const CustomRadioButton = styled.input`
  margin-right: 10px;
`
export const GrayCardUpload = styled.div`
  background: #f5f6fa;
  border-radius: 1rem;
  border: 1px solid #d6d7db;
  padding: 20px;

  & h4 {
    font-size: 1rem;
    font-weight: 700;
  }

  & .add-btn {
    background-color: var(--roxo-riber);
    color: var(--branco);
    width: auto;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    & .icon {
      color: var(--branco);
      font-size: 1.5rem;
    }
  }

  & .save-btn,
  & .clean-btn {
    width: auto;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1rem;
  }

  & .save-btn {
    & .icon {
      font-size: 1.5rem;
      color: var(--verde-riber);
    }
  }
  & .clean-btn {
    & .icon {
      font-size: 1.5rem;
      color: red;
    }
  }
`
export const Atualizando = styled.div`
  color: red;

  & p {
    color: red;
  }
`

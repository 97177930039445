import styled from 'styled-components'

export const BlankSpaceContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: center;

  & div {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    row-gap: 10px;
    width: 300px;
  }

  & .icon {
    color: #662d91;
    font-size: 60px;
  }

  & label {
    color: #353535;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
  }

  & p {
    color: #353535;
    font-size: 12px;
    line-height: 16px;
  }
`
export const BtnExportacao = styled.div`
  align-items: center;
  column-gap: 10px;
  display: flex;
  fill: #e8d4ec;
  flex-direction: row;
  justify-content: right;
  margin: 10px 0;
  line-height: 20px;
  stroke: #e8d4ec;
  text-align: right;

  & a {
    align-items: center;
    column-gap: 10px;
    display: flex;
    color: #e8d4ec;
    font-weight: 700;
    font-size: 14px;
    text-decoration: none;
    pointer-events: none;
  }

  &.active,
  &.active a {
    color: #662d91;
    cursor: pointer;
    fill: #662d91 !important;
    stroke: #662d91;
    pointer-events: auto;
  }

  &.active:hover,
  &.active:hover a {
    color: #816997;
    cursor: pointer;
    fill: #816997 !important;
    stroke: #816997;
    & label,
    pointer-events: auto;
    svg {
      pointer-events: none;
    }
  }
`

export const GrayCardUpload = styled.div`
  background: #f5f6fa;
  border-radius: 1rem;
  border: 1px solid #d6d7db;
  padding: 20px;

  & h4 {
    font-size: 1rem;
    font-weight: 700;
  }

  & .add-btn {
    background-color: var(--roxo-riber);
    color: var(--branco);
    width: auto;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    & .icon {
      color: var(--branco);
      font-size: 1.5rem;
    }
  }

  & .save-btn,
  & .clean-btn {
    width: auto;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1rem;
  }

  & .save-btn {
    & .icon {
      font-size: 1.5rem;
      color: var(--verde-riber);
    }
  }
  & .clean-btn {
    & .icon {
      font-size: 1.5rem;
      color: red;
    }
  }
`

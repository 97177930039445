import React, { useEffect, useRef, useCallback, useMemo, useState } from 'react'
import { Modal, ModalHeader, Row } from 'reactstrap'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import * as atendimentosActions from 'store/modules/atendimento/actions'
import {
  ClientReplyBubble,
  MessageInputContainer,
  ReceivedAt,
  StyledModalBody,
  UserMessageBubble,
  Atualizando
} from 'views/atendimento/style'
import InputVariable from 'components/Inputs/InputVariable'
import { ATENDIMENTO } from 'store/modules/atendimento/types'
import useRedux from 'hooks/useRedux'
import { IoIosSend } from 'react-icons/io'
import moment from 'moment'
import classNames from 'classnames'
import { capitalizaPrimeiraLetra } from 'util/masks'

interface VisualizarSMSModalProps {
  isOpen: boolean
  toggle: () => void
  index: number
}

const VisualizarSMSModal = ({
  isOpen,
  toggle,
  index
}: VisualizarSMSModalProps) => {
  const { atendimentosAbertos } = useSelector<ApplicationState, ATENDIMENTO>(
    (state) => state.atendimento
  )
  const { dispatch } = useRedux()
  const intervalRef = useRef<NodeJS.Timeout | null>(null)
  const messagesEndRef = useRef<HTMLDivElement | null>(null)
  const [counter, setCounter] = useState(10)
  const telefone = atendimentosAbertos[index]?.tabContato?.modalSms?.telefone

  const fetchMessages = useCallback(() => {
    if (telefone) {
      dispatch(atendimentosActions.getConversaSmsSaga(telefone, index))
    }
  }, [dispatch, telefone, index])

  const clearDataOnClose = useCallback(() => {
    dispatch(
      atendimentosActions.setInfoContatoModalSmsField('', 'mensagem', index)
    )
    dispatch(
      atendimentosActions.setInfoContatoModalSmsField([], 'resposta', index)
    )
    dispatch(
      atendimentosActions.setInfoContatoModalSmsField(
        null,
        'dataResposta',
        index
      )
    )
    dispatch(
      atendimentosActions.setInfoContatoModalSmsField(
        [],
        'responseClient',
        index
      )
    )
    dispatch(
      atendimentosActions.setInfoContatoModalSmsField(
        null,
        'dataResponse',
        index
      )
    )
  }, [dispatch, index])

  useEffect(() => {
    const startInterval = () => {
      intervalRef.current = setInterval(() => {
        setCounter((prev) => {
          if (prev > 1) {
            return prev - 1
          } else {
            fetchMessages()
            return 10
          }
        })
      }, 1000)
    }

    if (isOpen) {
      fetchMessages()
      startInterval()
    } else {
      clearDataOnClose()

      if (intervalRef.current) {
        clearInterval(intervalRef.current)
        intervalRef.current = null
      }

      setCounter(10)
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [isOpen, fetchMessages, clearDataOnClose])
  const handleSendSms = () => {
    dispatch(atendimentosActions.enviarSmsSaga(index))
  }
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight
    }
  }
  const smsMessages = useMemo(
    () => ({
      resposta: atendimentosAbertos[index]?.tabContato?.modalSms?.resposta,
      responseClient:
        atendimentosAbertos[index]?.tabContato?.modalSms?.responseClient
    }),
    [atendimentosAbertos, index]
  )

  useEffect(() => {
    scrollToBottom()
  }, [smsMessages])

  useEffect(() => {
    if (isOpen) {
      scrollToBottom()
    }
  }, [isOpen])

  const renderMessages = () => {
    const mensagensCliente =
      atendimentosAbertos[index]?.tabContato?.modalSms?.resposta?.map(
        (replyString) => {
          const { reply, receivedAt } = JSON.parse(replyString)
          const receivedAtDate = moment(receivedAt, 'DD/MM/YYYY HH:mm:ss')
          return {
            message: reply,
            timestamp: receivedAtDate.valueOf(),
            sender: 'client'
          }
        }
      ) || []

    const mensagensUsuario =
      atendimentosAbertos[index]?.tabContato?.modalSms?.responseClient?.map(
        (replyString) => {
          const { mensagem, dataCriacao } = JSON.parse(replyString)

          const dataCriacaoDate = new Date(dataCriacao)

          return {
            message: mensagem,
            timestamp: dataCriacaoDate.getTime(),
            sender: 'user'
          }
        }
      ) || []

    const todasMensagens = [...mensagensCliente, ...mensagensUsuario].sort(
      (a, b) => a.timestamp - b.timestamp
    )

    if (todasMensagens.length > 0) {
      return todasMensagens.map((mensagem, i) => {
        const formattedDateTime = new Date(mensagem.timestamp).toLocaleString(
          'pt-BR',
          {
            dateStyle: 'short',
            timeStyle: 'short'
          }
        )

        return (
          <div
            key={i}
            className={classNames(
              'messageWrapper',
              mensagem.sender === 'client' ? 'align-left' : 'align-right'
            )}
          >
            {mensagem.sender === 'client' ? (
              <ClientReplyBubble>
                <div className="message">{mensagem.message}</div>
                <ReceivedAt>{formattedDateTime}</ReceivedAt>
              </ClientReplyBubble>
            ) : (
              <UserMessageBubble>
                <div className="message">{mensagem.message}</div>
                <ReceivedAt>{formattedDateTime}</ReceivedAt>
              </UserMessageBubble>
            )}
          </div>
        )
      })
    }

    return (
      <ClientReplyBubble>
        <p>Nenhuma mensagem encontrada.</p>
      </ClientReplyBubble>
    )
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Visualizar e Responder SMS</ModalHeader>
      <StyledModalBody>
        <div className="messages-container" ref={messagesEndRef}>
          {renderMessages()}
          {atendimentosAbertos[index]?.tabContato?.modalSms?.loading && (
            <div className="loading-overlay">
              <div className="spinner"></div>
            </div>
          )}
        </div>
      </StyledModalBody>
      <Row className="p-3">
        <Atualizando>
          <p className="atualizando">Atualizando em: {counter} segundos</p>
        </Atualizando>
        <MessageInputContainer>
          <InputVariable
            className="inputField"
            rawValue={
              atendimentosAbertos[index]?.tabContato?.modalSms?.mensagem
            }
            onRawChange={(value) =>
              dispatch(
                atendimentosActions.setInfoContatoModalSmsField(
                  value,
                  'mensagem',
                  index
                )
              )
            }
            placeholder="Digite aqui..."
            variables={[
              {
                descricao: 'Nome_Cliente',
                campoEquivalente: capitalizaPrimeiraLetra('nome')
              },
              {
                descricao: 'Valor_da_Oportunidade',
                campoEquivalente: 'valor'
              }
            ]}
          />
          <button className="sendButton" onClick={handleSendSms}>
            <IoIosSend />
          </button>
        </MessageInputContainer>
      </Row>
    </Modal>
  )
}

export default VisualizarSMSModal

import { Reducer } from 'redux'
import { CARTEIRA, TYPES } from './types'

const INITIAL_STATE: CARTEIRA = {
  carteiras: [],
  dividirClientes: [],
  idsTransferencia: [],
  previewTranferir: {
    previewLoja: [],
    previewConsultores: []
  },
  details: {
    visualizacao: false,
    lojaId: '',
    qtdClientesSemConsultor: 0,
    detalheConsultorDto: []
  },
  selectedLoja: {
    nome: '',
    id: ''
  },
  selectedConsultor: {
    id: '',
    nome: ''
  },
  activeTab: 'search',
  search: {
    pesquisa: '',
    clientesSemConsultores: '',
    ativo: '',
    centroCustoId: ''
  },
  centrosCustos: [],
  register: {
    id: '',
    descricao: ''
  },
  pagination: {
    page: 0,
    pageSize: 10,
    totalRegistros: 0
  },
  visualizacao: false,
  itemSelectedId: '',
  showConfirmacaoExclusao: false,
  clientNoConsultantsModal: {
    lojaId: '',
    consultores: []
  },
  consultantsListModal: {
    lojaId: '',
    desconsiderarConsultorId: '',
    consultores: []
  },
  shopListModal: {
    lojaId: '',
    lojas: []
  },
  isOpenClientNoConsultantsModal: false,
  isOpenConsultantsListModal: false,
  isOpenShopListModal: false
}

const reducer: Reducer<CARTEIRA> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.SET_ACTIVE_TAB:
      return { ...state, activeTab: action.value }
    case TYPES.SET_ALL_CARTEIRAS:
      return { ...state, carteiras: action.value }
    case TYPES.SET_DETAILS_CARTEIRAS:
      return { ...state, details: action.value }
    case TYPES.SET_PESQUISA:
      return {
        ...state,
        search: { ...state.search, [action.field]: action.value }
      }
    case TYPES.SET_PAGINATION:
      return { ...state, pagination: action.value }

    case TYPES.SET_IDS_TRANSFERENCIA:
      return { ...state, idsTransferencia: action.value }

    case TYPES.SET_ID_NAME:
      return {
        ...state,
        selectedLoja: {
          nome: action.name,
          id: action.id
        }
      }
    case TYPES.SET_CONSULTOR:
      return {
        ...state,
        selectedConsultor: {
          nome: action.name,
          id: action.id
        }
      }

    case TYPES.SET_VISUALIZACAO:
      return {
        ...state,
        visualizacao: action.value
      }
    case TYPES.CLEAN_SEARCH:
      return { ...state, search: INITIAL_STATE.search }
    case TYPES.SET_CLIENT_NO_CONSULTANTS_MODAL:
      return { ...state, clientNoConsultantsModal: action.value }
    case TYPES.CLEAN_CLIENT_NO_CONSULTANTS_MODAL:
      return {
        ...state,
        clientNoConsultantsModal: INITIAL_STATE.clientNoConsultantsModal
      }
    case TYPES.SET_CONSULTANTS_LIST_MODAL:
      return { ...state, consultantsListModal: action.value }
    case TYPES.CLEAN_CONSULTANTS_LIST_MODAL:
      return {
        ...state,
        consultantsListModal: INITIAL_STATE.consultantsListModal
      }
    case TYPES.SET_SHOP_LIST_MODAL:
      return { ...state, shopListModal: action.value }
    case TYPES.CLEAN_SHOP_LIST_MODAL:
      return { ...state, shopListModal: INITIAL_STATE.shopListModal }
    case TYPES.SET_OPEN_CLIENT_NO_CONSULTANTS_MODAL:
      return { ...state, isOpenClientNoConsultantsModal: action.value }
    case TYPES.SET_OPEN_CONSULTANTS_LIST_MODAL:
      return { ...state, isOpenConsultantsListModal: action.value }
    case TYPES.SET_OPEN_SHOP_LIST_MODAL:
      return { ...state, isOpenShopListModal: action.value }
    case TYPES.SET_CENTRO_CUSTOS:
      return { ...state, centrosCustos: action.value }
    default:
      return state
  }
}

export default reducer

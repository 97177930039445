import { handlerError } from 'util/handlerError'
import { SagaIterator } from 'redux-saga'
import { call, fork, put, all, takeEvery, select } from 'redux-saga/effects'
import {
  AcompanhamentoProducao,
  AcompanhamentoProducaoFieldsTabela,
  PRODUCAO,
  TYPES
} from './types'
import * as producaoController from 'controller/producaoController'
import * as producaoActions from 'store/modules/producao/actions'
import * as loadingActions from 'store/modules/loading/actions'
import { ApplicationState } from 'store'
import { maskMoney, removeMaskCPF } from 'util/masks'
import { EnumServicos } from 'models/enums'
import { toast } from 'react-toastify'

interface GenericProps {
  type: string
}

function* find(): SagaIterator {
  const { filtros, pagination }: PRODUCAO = yield select(
    (state: ApplicationState) => state.producao
  )
  try {
    yield put(producaoActions.cleanTabelaAcompanhamentoProducao())
    yield put(loadingActions.setLoading(true))
    const response = yield call(
      producaoController.find,
      {
        adesao: filtros.adesao,
        cpf: removeMaskCPF(filtros.cpf),
        dataInicio: filtros.dataInicio,
        dataFim: filtros.dataFim,
        equipeId: filtros.equipeId,
        digitadorId: filtros.digitadorId
      },
      pagination.page,
      pagination.pageSize
    )
    const acompanhamentoProducao: AcompanhamentoProducaoFieldsTabela[] = []

    response?.data?.content?.registros?.map(
      (acompanhamento: AcompanhamentoProducao) => {
        acompanhamentoProducao.push({
          cpf: acompanhamento.cpf,
          nomeCliente: acompanhamento.nomeCliente,
          adesao: acompanhamento.adesao,
          valorTotal: maskMoney(acompanhamento.valorTotal?.toFixed(2)),
          quantidadeParcelas: acompanhamento.quantidadeParcelas,
          valorParcela: maskMoney(acompanhamento.valorParcela?.toFixed(2)),
          usuarioInstituicao: acompanhamento.usuarioInstituicao,
          status: acompanhamento.status,
          banco: acompanhamento.banco,
          numeroMatricula: acompanhamento.numeroMatricula,
          equipe: acompanhamento.equipe,
          id: acompanhamento.id,
          dataCriacao: acompanhamento.dataCriacao,
          descricaoTipoOperacao: acompanhamento.descricaoTipoOperacao,
          nomeDigitador: acompanhamento.nomeDigitador,
          produto:
            EnumServicos[acompanhamento.produto as keyof typeof EnumServicos],
          possuiSeguro: acompanhamento.possuiSeguro,
          motivoPendencia: acompanhamento.motivoPendencia
        })
      }
    )
    yield put(
      producaoActions.setTabelaAcompanhamentoProducao(acompanhamentoProducao)
    )
    yield put(
      producaoActions.setPaginacao({
        ...pagination,
        totalRegistros: response?.data?.content?.quantidadeTotal
      })
    )
    if (response?.data?.messages?.length > 0) {
      handlerError(response?.data)
    }
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

interface GetEquipesProps extends GenericProps {
  flagUsuario?: boolean
}

function* getEquipes({ flagUsuario }: GetEquipesProps): SagaIterator {
  try {
    const response = yield call(producaoController.getEquipes, flagUsuario)
    yield put(producaoActions.setEquipesOptions(response?.data?.content))
  } catch (error) {
    //
  }
}

interface GetUsuariosProps extends GenericProps {
  lojaId?: string
}

function* getUsuarios({ lojaId }: GetUsuariosProps): SagaIterator {
  try {
    const response = yield call(producaoController.getUsuarios, lojaId)
    yield put(producaoActions.setUsuariosOptions(response?.data?.content))
  } catch (error) {
    //
  }
}

function* getAnexos(): SagaIterator {
  const { modalPendenciasAnexoCrefaz }: PRODUCAO = yield select(
    (state: ApplicationState) => state.producao
  )
  try {
    yield put(loadingActions.setLoading(true))
    const response = yield call(producaoController.getAnexos, {
      cpf: modalPendenciasAnexoCrefaz.cpf,
      pagina: modalPendenciasAnexoCrefaz.paginacaoAnexos.page,
      registros: modalPendenciasAnexoCrefaz.paginacaoAnexos.pageSize
    })
    yield put(
      producaoActions.setModalPendenciasAnexoCrefazFields(
        response?.data?.content?.registros,
        'anexos'
      )
    )
    yield put(
      producaoActions.setModalPendenciasAnexoCrefazFields(
        {
          ...modalPendenciasAnexoCrefaz.paginacaoAnexos,
          totalRegistros: response?.data?.content?.quantidadeTotal
        },
        'paginacaoAnexos'
      )
    )
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getTiposArquivoCrefaz(): SagaIterator {
  try {
    const response = yield call(producaoController.getTiposArquivoCrefaz)
    yield put(
      producaoActions.setModalPendenciasAnexoCrefazFields(
        response?.data?.content,
        'tiposArquivo'
      )
    )
  } catch (error) {
    handlerError(error)
  }
}

function* salvarAnexo(): SagaIterator {
  const { modalPendenciasAnexoCrefaz }: PRODUCAO = yield select(
    (state: ApplicationState) => state.producao
  )
  try {
    yield put(loadingActions.setLoading(true))
    yield call(producaoController.salvarAnexo, {
      cpf: modalPendenciasAnexoCrefaz.cpf,
      propostaId: modalPendenciasAnexoCrefaz.propostaId,
      arquivo: modalPendenciasAnexoCrefaz.register.uploadedFile,
      tipoArquivoCrefazId: modalPendenciasAnexoCrefaz.register.tipoArquivo
    })
    yield put(
      producaoActions.setModalPendenciasAnexoCrefazFields(
        {
          tipoArquivo: '',
          uploadedFile: null
        },
        'register'
      )
    )
    yield put(producaoActions.getAnexosSaga())
    toast.success('Arquivo salvo com sucesso!')
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

interface AnexoProps extends GenericProps {
  nomeArquivo: string
}

function* visualizarAnexo({ nomeArquivo }: AnexoProps): SagaIterator {
  const { modalPendenciasAnexoCrefaz }: PRODUCAO = yield select(
    (state: ApplicationState) => state.producao
  )
  try {
    yield put(loadingActions.setLoading(true))
    const response = yield call(producaoController.downloadAnexo, {
      cpf: modalPendenciasAnexoCrefaz.cpf,
      nomeArquivo
    })

    const base64Content = response?.data?.content

    if (base64Content) {
      const byteCharacters = atob(base64Content)
      const byteNumbers = new Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)

      let mimeType = 'application/octet-stream'
      if (nomeArquivo.endsWith('.pdf')) {
        mimeType = 'application/pdf'
      } else if (nomeArquivo.endsWith('.png')) {
        mimeType = 'image/png'
      } else if (
        nomeArquivo.endsWith('.jpg') ||
        nomeArquivo.endsWith('.jpeg')
      ) {
        mimeType = 'image/jpeg'
      }

      const blob = new Blob([byteArray], { type: mimeType })

      const url = window.URL.createObjectURL(blob)
      window.open(url, '_blank')
    } else {
      throw new Error('Erro ao baixar conteúdo do arquivo.')
    }
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* downloadAnexo({ nomeArquivo }: AnexoProps): SagaIterator {
  const { modalPendenciasAnexoCrefaz }: PRODUCAO = yield select(
    (state: ApplicationState) => state.producao
  )
  try {
    yield put(loadingActions.setLoading(true))
    const response = yield call(producaoController.downloadAnexo, {
      cpf: modalPendenciasAnexoCrefaz.cpf,
      nomeArquivo
    })

    const base64Content = response?.data?.content

    if (base64Content) {
      const byteCharacters = atob(base64Content)
      const byteNumbers = new Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)
      const blob = new Blob([byteArray], { type: 'application/octet-stream' })

      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', nomeArquivo)
      document.body.appendChild(link)
      link.click()
      link.remove()

      window.URL.revokeObjectURL(url)
    } else {
      throw new Error('Erro ao baixar conteúdo do arquivo.')
    }
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* deletarAnexo({ nomeArquivo }: AnexoProps): SagaIterator {
  const { modalPendenciasAnexoCrefaz }: PRODUCAO = yield select(
    (state: ApplicationState) => state.producao
  )
  try {
    yield put(loadingActions.setLoading(true))
    yield call(producaoController.deletarAnexo, {
      cpf: modalPendenciasAnexoCrefaz.cpf,
      nomeArquivo
    })
    yield put(producaoActions.getAnexosSaga())
    toast.success('Arquivo deletado com sucesso!')
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

export function* watchFind() {
  yield takeEvery(TYPES.FIND_SAGA, find)
}

export function* watchGetEquipes() {
  yield takeEvery(TYPES.GET_EQUIPES_SAGA, getEquipes)
}

export function* watchGetUsuarios() {
  yield takeEvery(TYPES.GET_USUARIOS_SAGA, getUsuarios)
}

export function* watchGetAnexos() {
  yield takeEvery(TYPES.GET_ANEXOS_SAGA, getAnexos)
}

export function* watchGetTiposArquivoCrefaz() {
  yield takeEvery(TYPES.GET_TIPOS_ARQUIVO_CREFAZ_SAGA, getTiposArquivoCrefaz)
}

export function* watchSalvarAnexo() {
  yield takeEvery(TYPES.SALVAR_ANEXO_SAGA, salvarAnexo)
}

export function* watchVisualizarAnexo() {
  yield takeEvery(TYPES.VISUALIZAR_ANEXO_SAGA_DISPATCH, visualizarAnexo)
}

export function* watchDownloadAnexo() {
  yield takeEvery(TYPES.DOWNLOAD_ANEXO_SAGA_DISPATCH, downloadAnexo)
}

export function* watchDeletarAnexo() {
  yield takeEvery(TYPES.DELETAR_ANEXO_SAGA_DISPATCH, deletarAnexo)
}

function* producaoSaga() {
  yield all([
    fork(watchFind),
    fork(watchGetEquipes),
    fork(watchGetUsuarios),
    fork(watchGetAnexos),
    fork(watchGetTiposArquivoCrefaz),
    fork(watchSalvarAnexo),
    fork(watchVisualizarAnexo),
    fork(watchDownloadAnexo),
    fork(watchDeletarAnexo)
  ])
}

export default producaoSaga

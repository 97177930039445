/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import Dropzone from 'react-dropzone'
import { DropContainer, UploadArquivoWrapper } from './style'
import {
  FileIcon,
  defaultStyles,
  DefaultExtensionType,
  FileIconProps
} from 'react-file-icon'
import { filesize } from 'filesize'
import { BsCloudUpload } from 'react-icons/bs'
import { FiTrash } from 'react-icons/fi'
import { toast } from 'react-toastify'

interface UploadArquivoProps {
  onChange: (e: any) => any
  disabled?: boolean
  value: File | string | null
  invalid?: boolean
  removeSection?: boolean
  convertTo?: 'File' | 'base64'
  acceptedFiles?: Record<string, string[]>
  message?: string
}

export const UploadArquivo = ({
  onChange,
  disabled,
  value,
  invalid = false,
  removeSection = false,
  convertTo = 'File',
  acceptedFiles = {},
  message
}: UploadArquivoProps) => {
  function getExtension(fileName: string) {
    return (fileName.split('.').pop() || '') as keyof Record<
      DefaultExtensionType,
      Partial<FileIconProps>
    >
  }

  function fileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result as string)
      reader.onerror = (error) => reject(error)
    })
  }

  async function handleDropAccepted(files: File[]) {
    if (!files.length) {
      toast.error('Nenhum arquivo foi fornecido.')
      return
    }

    const [file] = files

    try {
      if (convertTo === 'base64') {
        const base64 = await fileToBase64(file)
        onChange(base64)
      } else {
        onChange(file)
      }
    } catch (error) {
      toast.error('Erro ao processar o arquivo')
    }
  }

  return (
    <UploadArquivoWrapper>
      <Dropzone
        disabled={disabled}
        multiple={false}
        onDropAccepted={handleDropAccepted}
        accept={
          Object.keys(acceptedFiles).length > 0 ? acceptedFiles : undefined
        }
      >
        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
          <DropContainer
            {...getRootProps()}
            isDragActive={isDragActive}
            isDragReject={isDragReject}
            invalid={invalid}
          >
            <input {...getInputProps()} />
            <BsCloudUpload className="icon" />
            <label>Arraste e solte ou clique para selecionar</label>
            <label>{message}</label>
          </DropContainer>
        )}
      </Dropzone>
      {value && (
        <div className="d-flex gap-3">
          <label className="preview">
            <div className="icon">
              <FileIcon
                extension={
                  typeof value === 'string'
                    ? 'base64'
                    : getExtension(value.name)
                }
                {...(typeof value === 'string'
                  ? {}
                  : defaultStyles[getExtension(value.name)])}
              />
            </div>
            <span>
              {typeof value === 'string' ? 'base64' : filesize(value.size)}
            </span>
            {typeof value === 'string' ? 'Arquivo em base64' : value.name}
          </label>
          <div className="d-flex align-items-center">
            {removeSection && (
              <div
                onClick={() => onChange(null)}
                className="btn remove-section"
              >
                <FiTrash className="icon" />
                <span>Remover</span>
              </div>
            )}
          </div>
        </div>
      )}
    </UploadArquivoWrapper>
  )
}

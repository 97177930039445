import React, { useCallback } from 'react'
import InputCustom from 'components/Inputs'
import CustomSelect from 'components/Select'
import { FiTrash2 } from 'react-icons/fi'
import { Col, Row } from 'reactstrap'
import { Referencia as ReferenciaType } from 'models/atendimento/types'
import { shallowEqual, useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { ATENDIMENTO } from 'store/modules/atendimento/types'
import * as atendimentoActions from 'store/modules/atendimento/actions'
import useRedux from 'hooks/useRedux'
import { BtnRemoveReferencia } from './style'
import { maskNome, maskNumber } from 'util/masks'

interface Props {
  index: number
  indexReferencia: number
}

const Referencia = ({ index, indexReferencia }: Props) => {
  const { atendimentosAbertos } = useSelector<ApplicationState, ATENDIMENTO>(
    (state) => state.atendimento,
    shallowEqual
  )

  const { dispatch } = useRedux()

  type Fields = keyof ReferenciaType
  const onChange = useCallback(
    (value: string, field: Fields) => {
      const referencias = [
        ...(atendimentosAbertos[index].tabInformacoes.infoCliente.referencias ||
          [])
      ]
      referencias[indexReferencia] = {
        ...referencias[indexReferencia],
        [field]: value
      }
      dispatch(
        atendimentoActions.setInformacoesField(
          referencias,
          'referencias',
          index
        )
      )
    },
    [atendimentosAbertos, dispatch, index, indexReferencia]
  )

  const handleRemove = useCallback(() => {
    dispatch(atendimentoActions.removerReferencia(indexReferencia, index))
  }, [dispatch, index, indexReferencia])

  return (
    <div className="container-fluid mb-4">
      <Row>
        <Col md={4}>
          <label className="label-12">Nome*</label>
          <InputCustom
            type="text"
            placeholder="Digite aqui"
            onChange={(e) => {
              const value = maskNome(e.target.value)
              onChange(value, 'nome')
            }}
            value={
              atendimentosAbertos[index].tabInformacoes.infoCliente.referencias[
                indexReferencia
              ].nome
            }
            invalid={
              atendimentosAbertos[index].tabInformacoes?.invalids.referencias[
                indexReferencia
              ].nome
            }
            maxLength={100}
          />
        </Col>
        <Col md={1}>
          <label className="label-12">DDD*</label>
          <InputCustom
            type="text"
            placeholder="Digite aqui"
            onChange={(e) => {
              onChange(maskNumber(e.target.value), 'ddd')
            }}
            value={maskNumber(
              atendimentosAbertos[index].tabInformacoes.infoCliente.referencias[
                indexReferencia
              ].ddd
            )}
            invalid={
              atendimentosAbertos[index].tabInformacoes?.invalids.referencias[
                indexReferencia
              ].ddd
            }
            maxLength={3}
          />
        </Col>
        <Col md={3}>
          <label className="label-12">Número*</label>
          <InputCustom
            type="text"
            placeholder="Digite aqui"
            onChange={(e) => {
              onChange(maskNumber(e.target.value), 'numero')
            }}
            value={maskNumber(
              atendimentosAbertos[index].tabInformacoes.infoCliente.referencias[
                indexReferencia
              ].numero
            )}
            invalid={
              atendimentosAbertos[index].tabInformacoes?.invalids.referencias[
                indexReferencia
              ].numero
            }
            maxLength={9}
          />
        </Col>
        <Col md={4}>
          <label className="label-12">Parentesco*</label>
          <CustomSelect
            options={
              atendimentosAbertos[index].tabInformacoes?.optionsSelect
                .grausReferencia
            }
            placeholder="Selecione"
            onChange={(e) => {
              onChange(e, 'grauReferenciaCrefazId')
            }}
            value={
              atendimentosAbertos[index].tabInformacoes.infoCliente.referencias[
                indexReferencia
              ].grauReferenciaCrefazId
            }
            accessorLabel="descricao"
            accessorValue="id"
            invalid={
              atendimentosAbertos[index].tabInformacoes?.invalids.referencias[
                indexReferencia
              ].grauReferenciaCrefazId
            }
          />
        </Col>
      </Row>
      <Row>
        <Col className="d-flex my-2">
          <BtnRemoveReferencia
            onClick={() => {
              handleRemove()
            }}
            className="btn"
          >
            <FiTrash2 className="icon" />
            Remover referência
          </BtnRemoveReferencia>
        </Col>
      </Row>
    </div>
  )
}

export default React.memo(Referencia)

import { APICore } from 'helpers/api/apiCore'
import { removeMaskCPF } from 'util/masks'

const api = new APICore()

export interface FindProps {
  dataInicio: string | null
  dataFim: string | null
  equipeId: string | null
  digitadorId: string | null
  adesao: string
  cpf: string
}

export function find(params: FindProps, pagina: number, registros: number) {
  return api.getWithoutUndefined(`Producao/find`, {
    pagina: pagina + 1,
    registros,
    ...params
  })
}

export function getEquipes(flagUsuario?: boolean) {
  return api.getWithoutUndefined(`Generic/lojas`, {
    flagUsuario
  })
}

export function getUsuarios(lojaId?: string) {
  return api.getWithoutUndefined(`Generic/usuarios`, {
    lojaId
  })
}

export function getAnexos(params: {
  cpf: string
  pagina: number
  registros: number
}) {
  const cpfFormated = removeMaskCPF(params.cpf)
  return api.get(`Crefaz/arquivos`, {
    ...params,
    pagina: params.pagina + 1,
    cpf: cpfFormated
  })
}

export function getTiposArquivoCrefaz() {
  return api.get(`Generic/tipo-arquivos-crefaz`, null)
}

export function salvarAnexo(params: {
  cpf: string
  propostaId?: string
  tipoArquivoCrefazId: string
  arquivo: File | null
}) {
  return api.createWithFile(
    `Crefaz/upload-arquivo?cpf=${params.cpf}&tipoArquivoCrefazId=${
      params.tipoArquivoCrefazId
    }&envioCrefaz=${true}&propostaId=${params.propostaId}`,
    {
      arquivo: params.arquivo
    }
  )
}

export function downloadAnexo(params: { cpf: string; nomeArquivo: string }) {
  return api.update(
    `Crefaz/download-arquivo?cpf=${params.cpf}&nomeArquivo=${params.nomeArquivo}`,
    null
  )
}

export function deletarAnexo(params: { cpf: string; nomeArquivo: string }) {
  return api.delete(
    `Crefaz/arquivo?cpf=${params.cpf}&nomeArquivo=${params.nomeArquivo}`
  )
}
